import { GetApiRequest } from '@/plugins/api/base'

export class GetAdditionalResult extends GetApiRequest {
  constructor(context, data = {}) {
    const culture = context.i18n.locale.value

    const url = `api/results/${culture}/additional/sport${data.sportId}/event${data.id}`
    super(context, url)
  }
}
