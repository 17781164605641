import { GetApiRequest } from '@/plugins/api/base'

export class GetLineTournamentsBySportAndCountry extends GetApiRequest {
  constructor(context, data) {
    const culture = context.i18n.locale.value

    const url = `/api/line/${culture}/tournaments/sport${data.sportId}/country${data.countryId}/${data.startsWithin}`
    super(context, url)
  }
}
