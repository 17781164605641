import { GetApiRequest } from '@/plugins/api/base'

export class GetUserPersonalInfo extends GetApiRequest {
  constructor(context) {
    const body = {
      Culture: context.i18n.locale.value,
    }

    const url = `/Bsw/BCService.asmx/getUserPersonalInfo`
    super(context, url, body)
  }
}
