import { GetApiRequest } from '@/plugins/api/base'

export class GetConcreteContentPage extends GetApiRequest {
  constructor(context, data = {}) {
    const params = {
      pageUrlName: data.pageName,
      culture: context.i18n.locale.value,
      loadChildren: true,
    }

    const url = 'api/content/getConcreteContentPage'

    super(context, url, params)
  }
}
