import { computed, toRef } from 'vue'

export default function (props) {
  const label = computed(() => {
    const propValue = toRef(props, 'label')
    if (propValue.value !== undefined) {
      return propValue.value
    }

    return 'label'
  })

  const getOptionLabel = computed(() => {
    const callback = toRef(props, 'getOptionLabel')
    if (callback.value) {
      return callback.value
    }

    return (option) => {
      if (option !== null && typeof option === 'object') {
        return option[label.value]
      }
      return ''
    }
  })

  return { label, getOptionLabel }
}
