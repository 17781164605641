<script setup>
import { computed, defineProps } from 'vue'

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  active: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },

  href: {
    type: String,
    default: null,
  },
  to: {
    type: String,
    default: null,
  },
  variant: {
    type: String,
    default: 'primary',
    validator(value) {
      return ['primary', 'secondary', 'black'].includes(value)
    },
  },
  type: {
    type: String,
    default: 'button',
    validator(value) {
      return ['button', 'submit'].includes(value)
    },
  },
  tag: {
    type: String,
    default: 'button',
    validator(value) {
      return ['button', 'a', 'RouterLink'].includes(value)
    },
  },
  size: {
    type: String,
    default: 'md',
    validator(value) {
      return ['sm', 'md', 'lg', 'full'].includes(value)
    },
  },
  fill: {
    type: String,
    default: '',
    validator(value) {
      return ['', 'white'].includes(value)
    },
  },
  justifyContent: {
    type: String,
    default: 'centre',
    validator(value) {
      return ['centre', 'start', 'end'].includes(value)
    },
  },

  noPadding: {
    type: Boolean,
    default: false,
  },
})

const classes = computed(() => ({
  [`base-button_variant-${props.variant}`]: true,
  [`base-button_size-${props.size}`]: true,
  [`base-button_justify-content-${props.justifyContent}`]: true,
  [`base-button_fill-${props.fill}`]: props.fill,
  ['base-button_disabled']: props.disabled,
  [`base-button_active`]: props.active,
  ['base-button_loading']: props.loading,
  ['base-button_no-padding']: props.noPadding,
}))
</script>

<template>
  <div class="base-button" :class="classes">
    <Component
      class="base-button__component"
      :is="props.tag"
      :href="props.href || props.to"
      :to="props.to"
      :type="props.type"
      :disabled="props.disabled || props.loading"
    >
      <div class="base-button__text">
        <slot />
      </div>
      <div class="base-button__bg"></div>
      <div class="base-button__bg-border"></div>
    </Component>
    <div class="base-button__triangle"></div>
    <div class="base-button__triangle-border"></div>
  </div>
</template>

<style lang="scss" scoped>
@import 'base-button';
</style>
