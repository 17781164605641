import { PostApiRequest } from '@/plugins/api/base'
// TODO GET or POST?

export class GetUserBets extends PostApiRequest {
  constructor(context, data = {}) {
    const body = {
      begin: data.begin,
      count: data.count,
      end: data.end,
      page: data.page || 0,
      periodType: data.periodType || 0,
      showLossBets: data.showLossBets || true,
      showWinBets: data.showWinBets || true,
      showWorkBets: data.showWorkBets || true,
    }

    const url = `/Bsw/BCService.asmx/GetUserBets`
    super(context, url, body)
  }
}
