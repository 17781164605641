<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  size: {
    type: String,
    default: 'sm',
    validator(value) {
      return ['sm', 'md', 'lg'].includes(value)
    },
  },
})
</script>

<template>
  <router-link
    class="logo"
    :class="{ [`logo_size-${props.size}`]: true }"
    to="/"
  >
    <img src="@/assets/images/logo.png" class="logo__img" alt="" />
  </router-link>
</template>

<style lang="scss" scoped>
@import 'base-logo';
</style>
