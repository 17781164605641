<script setup>
import { computed, watch, defineProps, defineEmits } from 'vue'

const props = defineProps({
  label: {
    type: String,
    default: null,
  },

  containerComponent: {
    type: String,
    default: 'label',
  },

  labelActive: {
    type: Boolean,
    default: false,
  },

  placeholderPosition: {
    type: String,
    default: 'static',
  },

  tooltip: {
    type: String,
    default: null,
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  noBorder: {
    type: Boolean,
    default: false,
  },

  error: {
    type: String,
    default: null,
  },
})

const emit = defineEmits(['errorTrigger'])

const invalid = computed(() => !!props.error)

watch(
  () => invalid.value,
  (e) => {
    emit('errorTrigger', e)
  },
  { immediate: true }
)
</script>

<template>
  <Component
    :is="containerComponent"
    :class="{ invalid, 'base-field_disabled': props.disabled }"
    class="base-field"
  >
    <span
      v-if="props.label"
      :class="{
        active: props.labelActive,
        flex: props.tooltip,
        [`base-field__placeholder_${props.placeholderPosition}`]: true,
      }"
      class="base-field__placeholder"
    >
      {{ props.label }}

      <!--      <VPopover-->
      <!--        v-if="props.tooltip"-->
      <!--        :triggers="['hover', 'focus']"-->
      <!--        placement="top"-->
      <!--        class="base-field__tooltip attention-icon"-->
      <!--      >-->
      <!--        <div class="attention-icon__container">-->
      <!--          <svg><use href="#icon-attention" /></svg>-->
      <!--        </div>-->

      <!--        <template #popover>-->
      <!--          {{ props.tooltip }}-->
      <!--        </template>-->
      <!--      </VPopover>-->
    </span>

    <span
      class="base-field__container"
      :class="{ 'no-border': props.noBorder }"
    >
      <slot />
    </span>

    <span class="base-field__error">{{ props.error }}</span>
  </Component>
</template>

<style lang="scss" scoped>
@import 'base-field';
</style>
