<script setup>
import { ref, watch } from 'vue'

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
    required: true,
  },
})
const afterActivate = ref(false)

watch(
  () => props.active,
  async (val) => {
    if (!val) {
      afterActivate.value = val
    }
  }
)
</script>

<template>
  <div>
    <slot name="activator" />

    <div
      :class="{
        ['base-expand_opened']: props.active,
        ['base-expand_after-opened']: afterActivate,
      }"
      class="base-expand"
    >
      <div class="base-expand__container">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'base-expand';
</style>
