import { AuthPlugin } from './base'

export { AuthPlugin } from './base'

let instance = null

export default {
  install: () => {},
}

export function useAuthPlugin(ctx) {
  if (!instance) {
    instance = new AuthPlugin(ctx)
  }

  return instance
}
