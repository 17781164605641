import { GetApiRequest } from '@/plugins/api/base'

export class GetAvailableCurrency extends GetApiRequest {
  constructor(context) {
    const culture = context.i18n.locale.value

    const url = `/GetAvailableCurrency/${culture}`
    super(context, url)
  }
}
