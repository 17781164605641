import TokenService from '@/plugins/api/TokenService'

export class BaseApiFactory {
  constructor(context, axios) {
    this.addRequestHandler(axios)

    this.context = context
    this.axios = axios
    this.toast = context.toast
  }

  addRequestHandler(axios) {
    axios.interceptors.request.use(this.onRequestHandlerSuccess.bind(this))
    axios.interceptors.response.use(
      this.onResponseHandlerSuccess.bind(this),
      this.onResponseHandlerFail.bind(this)
    )
  }

  onRequestHandlerSuccess(config) {
    const accessToken = TokenService.getLocalAccessToken()
    if (accessToken) {
      // TODO for jwt
      config.headers.Authorization = 'Bearer ' + accessToken
    }

    return config
  }

  onResponseHandlerSuccess(config) {
    // TODO check error
    if (!config.data.Ok && config.data.Error) {
      this.toast['error'](config.data.Error)
    }

    return config
  }

  async onResponseHandlerFail(err) {
    const originalConfig = err.config

    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 || err.response.status === 403) {
        // Remove access token if request fail
        TokenService.removeAccessToken()
        // Get refresh token
        const refreshToken = TokenService.getLocalRefreshToken()

        if (!refreshToken) {
          // If we don't have refresh token we need to start login
          // TODO redirect
          // this.context.config.globalProperties.$router.push("/login");
        } else {
          try {
            const { access, refresh } =
              await this.context.config.globalProperties.$api.auth.refresh({
                refresh: refreshToken,
              })

            TokenService.updateLocalAccessToken(access)
            TokenService.updateLocalRefreshToken(refresh)

            return this.axios(originalConfig)
          } catch (_error) {
            // If we don't have refresh token - request to get new tokens (access & refresh)
            TokenService.removeRefreshToken()
            return Promise.reject(_error)
          }
        }
      }
    }

    return Promise.reject(err)
  }

  // configureRequest(config) {
  //     const method = config.method.toUpperCase()
  //     console.warn(`[${method}] Making request to ${config.url}`)
  //
  //     // const token = this.context.$auth.strategy.token.get()
  //     // if (token) {
  //     //     config.headers.common.Authorization = token
  //     // }
  //
  //     return config
  // }
}

export class BaseApiRequest {
  constructor(context, method, url) {
    if (!url) throw new Error('path is not provided')

    this.url = url
    this.method = method
    this.toast = context.toast
    this.app = context
    // this.nuxtError = context.error
  }

  async fetch() {
    await console.warn('method is not provided')
  }

  async handleErrors(error) {
    // const token = this.app.$auth.strategy.token.get()
    const originalRequest = error.config

    // token &&
    if (
      error?.response?.status === 401 &&
      !originalRequest.headers.RepeatRequest
    ) {
      try {
        // await this.app.$auth.strategy.refreshTokens()
        // originalRequest.headers.Authorization =
        //     this.app.$auth.strategy.token.get()
        // originalRequest.headers.RepeatRequest = true
        // return await this.app.$axios(originalRequest)
      } catch (e) {
        const status = e?.response?.status
        if (status === 401) {
          await this.reloadPage()
        }

        throw error
      }
    }

    this.displayLogMessage(error)

    throw error
  }

  async reloadPage() {
    if (window && window.location) {
      await window.location.reload()
    }
    await this.app.redirect(this.app.route)
  }

  displayLogMessage(error) {
    if (error.response) {
      this.toast['error'](error.message)

      // TODO correct error handler

      // const messages = []

      // const errorStatusTitle = this.translateMessage('TOAST.ERROR_STATUS')
      // const errorStatus = `${errorStatusTitle}: ${error.response.status}`
      // messages.push(errorStatus)

      // const { validationErrors, errorMessage } = error.response.data

      // const errorsData = error.response.data
      // const validationErrors = error.response.data.errors
      //
      // if (validationErrors) {
      //   if (Object.keys(validationErrors).length === 0) {
      //     return
      //   }
      //
      //   for (const key of Object.keys(validationErrors)) {
      //     const content = validationErrors[key]
      //     let message
      //     if (Array.isArray(content)) {
      //       message = content.join('<br>')
      //     } else {
      //       message = content.toString()
      //     }
      //     // TODO toast
      //     this.toast['error'](message)
      //   }
      // } else if (Array.isArray(errorsData)) {
      //   errorsData.forEach((errorData) => {
      //     // TODO toast
      //     this.toast['error'](errorData.message)
      //     // this.app.config.globalProperties.$toast('error', errorData.message)
      //   })
      // } else {
      //   const errors = errorsData.error.details
      //   if (Array.isArray(errors)) {
      //     errors.forEach((errorData) => {
      //       // TODO toast
      //       this.toast['error'](
      //         `${errorData?.field ? errorData.field + ' - ' : ''}${
      //           errorData.message
      //         }`
      //       )
      //
      //       // this.app.config.globalProperties.$toast(
      //       //   'error',
      //       //   `${errorData?.field ? errorData.field + ' - ' : ''}${
      //       //     errorData.message
      //       //   }`
      //       // )
      //     })
      //   } else {
      //     const errorMessage = errors.message
      //     // TODO toast
      //     this.toast['error'](errorMessage)
      //     // this.app.config.globalProperties.$toast('error', errorMessage)
      //   }
      // }
    } else {
      console.error(error)
    }
  }
}

export class GetApiRequest extends BaseApiRequest {
  constructor(context, url, params) {
    const method = 'get'
    super(context, method, url)

    this.params = params
  }

  async fetch(axios) {
    try {
      const result = await this.get(axios, this.url, this.params)
      console.log(result, 'result-----------------1')

      return result.data || result
    } catch (e) {
      const result = await this.handleErrors(e)
      const data = result.data && result.data.data
      return data || result
    }
  }

  async get(axios, url, params) {
    return await axios.get(url, { params })
  }
}

export class ChangeDataApiRequest extends BaseApiRequest {
  constructor(context, method, url, data = null, config = {}) {
    super(context, method, url)

    this.data = data
    this.method = method
    this.config = config
  }

  async fetch(axios) {
    try {
      const result = await this.request(axios)
      console.log(result, 'result-----------------2')
      console.log(result.config.headers.getAuthorization(), '--------------3')
      console.log(result.config.headers['set-cookie'], '--------------4')
      console.log(result.config.headers, '--------------5')

      return result.data || result
    } catch (e) {
      const result = await this.handleErrors(e)
      const data = result.data && result.data.data
      return data || result
    }
  }

  async request(axios) {
    return await this.requestMethod(axios)
  }

  async requestMethod(axios) {
    const url = this.url
    const data = this.data
    const method = this.method

    if (method === 'delete') {
      return await axios[`${method}`](url, this.config)
    }
    return await axios[`${method}`](url, data, this.config)
  }
}

export class PostApiRequest extends ChangeDataApiRequest {
  constructor(context, url, data, config) {
    const method = 'post'
    super(context, method, url, data, config)
  }

  async request(axios) {
    return await this.post(axios)
  }

  async post(axios) {
    return await this.requestMethod(axios)
  }
}

export class PatchApiRequest extends ChangeDataApiRequest {
  constructor(context, url, data, config) {
    const method = 'patch'
    super(context, method, url, data, config)
  }

  async request(axios) {
    return await this.patch(axios)
  }

  async patch(axios) {
    return await this.requestMethod(axios)
  }
}

export class PutApiRequest extends ChangeDataApiRequest {
  constructor(context, url, data) {
    const method = 'put'
    super(context, method, url, data)
  }

  async request(axios) {
    return await this.put(axios)
  }

  async put(axios) {
    return await this.requestMethod(axios)
  }
}

export class DeleteApiRequest extends ChangeDataApiRequest {
  constructor(context, url, data, config = {}) {
    const method = 'delete'
    super(context, method, url, data, config)
  }

  async request(axios) {
    return await this.delete(axios)
  }

  async delete(axios) {
    return await this.requestMethod(axios)
  }
}
