export default {
  GENERAL: {
    HOME: 'Home',
    LIVE: 'Live',
    PRE_MATCH: 'Pre-match',
    GAMES: 'Games',
    RESULTS: 'Results',
    SUPPORT: 'Support',
    INFO: 'Info',
    PROVIDERS: 'Providers',
    BETS: 'Bets',
    DEPOSIT: 'Deposit',
    BONUSES: 'Bonuses',

    CANCEL: 'Cancel',
    SAVE: 'Save',
    CONFIRM: 'Confirm',
    SEE_ALL: 'See all',
    FOR: 'for',
    GO: 'GO!',
    SEARCH: 'Search',
    ENTER: 'Enter',
    ATTENTION: 'Attention!',
    NOTHING_FOUND: 'Nothing found!',

    18: '18+',
    CAUTION:
      "Lorem Ipsumis simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    BONUSES_PROMO: 'Bonuses and Promotions 2024',
    SUPPORT_24: 'Support 24\\7',
    LANDSCAPE_CAUTION: 'Please use device in vertical position',
    LANDSCAPE_CAUTION_DOWNLOAD: 'or install application',

    EVENTS_SEARCH: 'Quickly search for a sporting event',
    GAMES_SEARCH: 'Quick game search',
    SEARCH_RESULTS: 'Search results',
    NO_RESULTS: 'No results',
    PLEASE_TRY_AGAIN: 'Please try again',
    FROM: 'From',
    TO: 'to',
    CHANGE: 'Change',
    SELL: 'Sell',
    APPLY: 'Apply',

    FAVOURITES: 'Favourites',
    FAVOURITES_EMPTY_TITLE: 'No saved events',
    FAVOURITES_EMPTY_DESCRIPTION:
      'To save an elements and track changes to it, click on the icon in the event',
    SELECT_LANGUAGES: 'Select languages',
  },

  LANGUAGE: {
    UKRAINIAN: 'Ukrainian',
    ENGLISH: 'English',
    SPANISH: 'Spanish',
    FRENCH: 'French',
    TURKISH: 'Turkish',
    PORTUGUESE: 'Portuguese',
    RUSSIAN: 'Russian',
  },

  TOAST: {
    SETTINGS_MODAL_SAVE_SUCCESS: 'Settings have been updated successfully',
    RECOVER_LOGIN_ERROR: 'User with this email or phone number was not found',
    RECOVERY_SENT_EMAIL:
      'A password recovery email has been sent to your email',
  },

  ERRORS: {
    REQUIRED_FIELD: 'This field is required',
    NOT_CORRECT: '',
    PASSWORD:
      'Passwords must contain at least 8 characters, including numbers, uppercase and uppercase Latin letters',
    MISMATCH: 'Password mismatch',
    USER_EXISTS: 'User already exists',
    PHONE_FORMAT: 'Phone number is not correct',
    EMAIL_FORMAT: 'Email is not correct',
  },

  SPORT_LINE: {
    TOTALS: 'Totals',
    HANDICAP: 'Handicap',
  },

  EVENT_LINE: {
    INFORMATION: 'Information',
  },

  EVENT_LIVE: {
    MATCH_INFO: 'Match info',
    MATCH_CENTER: 'Match center',
    MATCH_TV: 'Match TV',
    ODDS: 'Odds',
    ONLY_REGISTERED_USERS: 'Online broadcast access only registered users',
  },

  RESULTS: {
    SELECT_PERIOD: 'Select period',
    SELECT_SPORT: 'Select a sport',
    CHOOSE_COUNTRY: 'Choose the country',
    TOURNAMENT: 'Tournament',
    SHOW_RESULT: 'Show result',
  },

  AUTH: {
    LOGIN: 'Login',
    LOGOUT: 'Logout',
    REGISTRATION: 'Registration',
    EMAIL: 'Email',
    PHONE: 'Phone',
    ENTER_EMAIL: 'Enter email',
    PASSWORD: 'Password',
    ENTER_PASSWORD: 'Enter password',
    NUMBER_EMAIL: 'Phone number or email',
    LOGIN_TITLE: 'Welcome',
    FORGOT: 'Forgot your password?',
    WARNING_TEXT:
      'After registration, you cannot change currency in your profile',
    CONFIRM_TEXT:
      'I hereby confirm that I am over 18 years of age and have read and agree to all applicance',
    RULES_LINK: 'rules and regulations',
    RECOVERY: 'Recovery',
    ACCESS_RECOVERY: 'Access recovery',
    NEW_PASSWORD: 'New Password',
    RESTORE: 'Restore',
    DID_NOT_CODE: 'Didn`t receive the code?',
    TIMER_TEXT: 'You can request a new code in',
    SECONDS: 'seconds',
    REQUEST_CODE: 'Request a new code',
    OLD_PASSWORD: 'Old password',
    CREATE_PASSWORD: 'Create password',
    CREATE_NEW_PASSWORD: 'Create new password',
    REPEAT_PASSWORD: 'Repeat password',
    VERIFICATION_CODE: 'Verification code',
    WILL_RESET_PASSWORD: 'Will reset the password',
    SELECT_CURRENCY: 'Select currency',
    CURRENCY: 'Currency',
  },

  HOT_EVENTS: {
    TITLE: 'Hot events',
  },

  GAMES: {
    ALL: 'All games',
    GAME: 'Game',
  },

  COUPON: {
    BETS: 'Bets',
    PLACE_BET: 'Place your bets',
    EMPTY_DESCRIPTION: 'Your selection will appear in this area',
    CLEAR_ALL: 'Clear all',
    MAKE_BET: 'Make a bet',
    CANCEL: 'Cancel deletion',
    POSSIBLE_WINNINGS: 'Possible winnings',
    BET_AMOUNT: 'Bet amount',
    TOTAL_ODDS: 'Total odds',
    ONE_CLICK: 'Set up a bet in 1 click',
    ONE_CLICK_DESCRIPTION:
      'You bet will be placed instantly upon clicking on the selected event without being added to the slip',
    ONE_CLICK_TOOLTIP:
      'You bet will be placed instantly upon clicking on the selected event without being added to the slip. All bets can be viewed on the page “Transaction history - Bid history',
    SINGLE: 'Single',
    MULTI: 'Multi',
    SYSTEM: 'System',
    CALCULATOR: 'System calculator',
    CHOOSE_SYSTEM: 'Choose system',
    ODDS: 'Odds',
    WINNING: 'Winning',
    LOSING: 'Losing',
    RETURN: 'Return',
    OPTION_AMOUNT: 'Amount of one option',
    RESET: 'Reset',
    CALCULATE: 'Calculate',
    SETTINGS: 'Setting',
    ODDS_SETTINGS: 'Odds display setting',
    DECIMAL: 'Decimal',
    FRACTIONAL: 'Fractional',
    AMERICAN: 'American',
    HONG_KONG: 'Hong Kong',
    INDONESIAN: 'Indonesian',
    MALAYSIAN: 'Malaysian',
    SHOW_EVENT_IDS: 'Show event IDs',
    COUPON_SETTINGS: 'Coupon settings',
    ACCEPT_ALL_ODDS: 'Accept any odds changes',
    ACCEPT_INCREASE: 'Accept only increased odds',
    NOT_ACCEPT: 'Do not accept any odds changes',
    QUICK_BETS: 'Setting up quick bets ',
    SPECIFY_BET_AMOUNT: 'Specify the bet size you use most often',
    NOT_ENOUGH_FUNDS: 'There are not enough funds to place a bet',
    PAYMENT: 'Payment',
  },

  PROFILE: {
    BASIC_INFO: 'Basic information',
    DEPOSITS: 'Deposits',
    PAYMENTS: 'Payments',
    BETS_HISTORY: 'Bets history',
    TRANSACTIONS_HISTORY: 'History of transaction',
    YOUR_BONUSES: 'Your bonuses',
    MESSAGES: 'Messages',
    PERSONAL_DATA: 'Personal data',
    YOUR_LOGIN: 'Your login',
    YOUR_NUMBER: 'Your account number',
    IDENTIFICATION: 'Identification',
    PASSPORT: 'Series and passport number',
    LAST_NAME: 'Last name',
    FIRST_NAME: 'First name',
    CHANGE_PASSWORD: 'Change password',
    UPLOAD_PHOTO: 'Upload a photo of the document',
    PHOTO_VALIDATE: 'Accept 1 file format jpeg, jpg, pdf, Heic up to 10 MB ',
    DATE_OF_BIRTH: 'Date of Birth',
    SELECT_REPLENISHMENT: 'Select replenishment method',
    THROUGH_THE_CASHIER: 'Through the cashier',
    DEPOSITS_TEXT:
      'You can top up account at our payment points. To do this, contact and tell him one of the identifiers',
    BANK_CARD: 'Bank card',
    COMMISSION: 'Commission',
    AMOUNT: 'Amount',
    PAY: 'Pay',
    PAYPAL: 'PayPal',
    SELECT_WITHDRAWAL: 'Select  a withdrawal method',
    AMOUNT_ISSUED: 'Specify the amount to be issued',
    ISSUE: 'Issue',
    ENTER_CARD: 'Please enter your credit card number',
    WARNING_TITLE: 'Insufficient funds on balance',
    WARNING_TEXT:
      'To withdraw, you must have a minimum balance of {count} USDT',
    HISTORY: 'History',
    COUPON_ID: 'Coupon ID',
    TYPE: 'Type',
    TOTAL_ODDS: 'Total odds',
    YOUR_STAKE: 'Your stake',
    RESULT: 'Result',
    YOUR_WINNINGS: 'Your winnings',
    YOUR_BET: 'Your bet',
    BONUS: 'Bonus',
    FILTER: 'Filter',
    CLEAR_ALL: 'Clear all',
    MAKE_BET: 'Make a bet',
    PERIOD: 'Period',
    TRANS_ID: 'Trans ID',
    PAYMENT_SYSTEM: 'Payment System',
    DESCRIPTION: 'Description',
    BONUSES: 'Bonuses',
    BIRTH_DAY: 'Date of Birth',
  },

  MESSAGES: {
    ALL: 'All',
    MASS: 'Mass',
    PRIVATE: 'Private',
    SUBJECT: 'Subject',
    TYPE_HERE: 'Type here',
    NOT_MESSAGES: 'You don`t have any messages',
    SUPPORT: 'Support',
  },
}
