import { ref } from 'vue'

const localStorageKeys = {
  GAMES_FAVOURITE_LIST: 'games-favourite-list',
  LINE_FAVOURITE_LIST: 'line-favourite-list',
  LIVE_FAVOURITE_LIST: 'live-favourite-list',
  LINE_COUPON_LIST: 'line-coupon-list',
  LIVE_COUPON_LIST: 'live-coupon-list',
}

export function useLocalStorage(type, options = undefined) {
  const currentList = ref([])

  const currentKey = localStorageKeys[type]

  const storageCurrentList = localStorage.getItem(currentKey)
  if (storageCurrentList) {
    currentList.value = JSON.parse(storageCurrentList)
  }

  function isAddedToStorage(item) {
    return currentList.value.find((listItem) => {
      if (options?.compareCallback) {
        return options.compareCallback(listItem, item)
      }

      return JSON.stringify(listItem) === JSON.stringify(item)
    })
  }

  function toggleStorageItem(item) {
    if (!item) {
      return
    }

    if (isAddedToStorage(item)) {
      removeStorageItem(item)
    } else {
      setStorageItem(item)
    }

    localStorage.setItem(currentKey, JSON.stringify(currentList.value))
  }

  function setStorageItem(item) {
    currentList.value.push(item)
  }

  function removeStorageItem(item) {
    currentList.value = currentList.value.filter((game) => {
      if (options?.removeCallback) {
        return options.removeCallback(game, item)
      }

      return JSON.stringify(game) !== JSON.stringify(item)
    })
  }

  function resetData() {
    currentList.value = []
  }

  return {
    currentList,
    isAddedToStorage,
    toggleStorageItem,
    resetData,
  }
}
