import { GetApiRequest } from '@/plugins/api/base'

export class GetLineSports extends GetApiRequest {
  constructor(context, data) {
    const culture = context.i18n.locale.value

    const url = `/api/line/${culture}/sports/${data.startsWithin}`
    super(context, url)
  }
}
