import { ApiFactory } from '@/plugins/api/logic/ApiFactory'
import { GetUserBalance } from '@/plugins/api/logic/profile/get-user-balance'
import { GetUserPersonalInfo } from '@/plugins/api/logic/profile/get-user-personal-info'
import { GetUserFullData } from '@/plugins/api/logic/profile/get-user-full-data'
import { GetUserBets } from '@/plugins/api/logic/profile/get-user-bets'
import { GetOrderedTransactions } from '@/plugins/api/logic/profile/get-ordered-transactions'

export class ProfileController extends ApiFactory {
  async getUserBalance() {
    const request = new GetUserBalance(this.context)
    return await request.fetch(this.axios)
  }

  async getUserPersonalInfo() {
    const request = new GetUserPersonalInfo(this.context)
    return await request.fetch(this.axios)
  }

  async getUserFullData() {
    const request = new GetUserFullData(this.context)
    return await request.fetch(this.axios)
  }

  async getUserBets(data) {
    const request = new GetUserBets(this.context, data)
    return await request.fetch(this.axios)
  }

  async getOrderedTransactions() {
    const request = new GetOrderedTransactions(this.context)
    return await request.fetch(this.axios)
  }
}
