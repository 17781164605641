import { ApiFactory } from '@/plugins/api/auth/ApiFactory'
import { GetLineSports } from '@/plugins/api/logic/line/get-sports'
import { GetLineCountries } from '@/plugins/api/logic/line/get-countries'
import { GetLineHotEvents } from '@/plugins/api/logic/line/get-hot-events'
import { GetLineTournamentsBySportAndCountry } from '@/plugins/api/logic/line/get-tournaments-by-sport-and-countries'
import { GetLineTournamentsWithEvents } from '@/plugins/api/logic/line/get-tournaments-with-events'
import { GetLineEventById } from '@/plugins/api/logic/line/get-event-by-id'

export class LineEventsController extends ApiFactory {
  async getLineSports(data) {
    const request = new GetLineSports(this.context, data)
    return await request.fetch(this.axios)
  }

  async getLineCountries(data) {
    const request = new GetLineCountries(this.context, data)
    return await request.fetch(this.axios)
  }

  async getLineHotEvents(data) {
    const request = new GetLineHotEvents(this.context, data)
    return await request.fetch(this.axios)
  }

  async getLineTournamentsBySportAndCountry(data) {
    const request = new GetLineTournamentsBySportAndCountry(this.context, data)
    return await request.fetch(this.axios)
  }

  async getLineTournamentsWithEvents(data) {
    const request = new GetLineTournamentsWithEvents(this.context, data)
    return await request.fetch(this.axios)
  }

  async getLineEventById(data) {
    const request = new GetLineEventById(this.context, data)
    return await request.fetch(this.axios)
  }
}
