import { PostApiRequest } from '@/plugins/api/base'

export class SendRestorePasswordEmail extends PostApiRequest {
  constructor(context, data = {}) {
    const culture = context.i18n.locale.value
    const body = {
      Email: data.email,
      Culture: culture,
    }

    const url = '/api/account/sendRestorePasswordEmail'
    super(context, url, body)
  }
}
