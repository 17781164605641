export function useExpand() {
  function enter(element, callback = undefined) {
    const width = getComputedStyle(element).width

    element.style.width = width
    element.style.position = 'absolute'
    element.style.visibility = 'hidden'
    element.style.height = 'auto'

    const { height } = getComputedStyle(element)

    element.style.width = ''
    element.style.position = ''
    element.style.visibility = ''
    element.style.height = '0px'

    getComputedStyle(element).height

    requestAnimationFrame(() => {
      element.style.height = height
    })

    if (callback) {
      callback()
    }
  }

  function afterEnter(element, callback = undefined) {
    requestAnimationFrame(() => {
      element.style.height = 'auto'
    })

    if (callback) {
      callback()
    }
  }

  function leave(element, callback = undefined) {
    requestAnimationFrame(() => {
      const { height } = getComputedStyle(element)

      element.style.height = height

      getComputedStyle(element).height
      element.style.height = '0px'
    })

    if (callback) {
      callback()
    }
  }

  return { enter, afterEnter, leave }
}
