import { GetApiRequest } from '@/plugins/api/base'

export class GetSports extends GetApiRequest {
  constructor(context, data = {}) {
    const culture = context.i18n.locale.value

    const url = `api/results/${culture}/sports/${data.periodType}/${data.start}/${data.end}`
    super(context, url)
  }
}
