<script setup>
import BaseField from '@/components/base/base-field/BaseField'

import { computed, defineProps, defineEmits, ref } from 'vue'
import { isNumber } from 'lodash'

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: null,
  },

  type: {
    type: String,
    default: 'text',
    validator(value) {
      return ['text', 'password', 'number', 'email'].includes(value)
    },
  },

  label: {
    type: String,
    default: null,
  },

  placeholder: {
    type: [String, Boolean],
    default: null,
  },

  tooltip: {
    type: String,
    default: null,
  },

  required: {
    type: Boolean,
    default: false,
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  clearable: {
    type: Boolean,
    default: false,
  },

  loading: {
    type: Boolean,
    default: false,
  },

  icon: {
    type: String,
    default: null,
  },

  noBorder: {
    type: Boolean,
    default: false,
  },

  copy: {
    type: Boolean,
    default: false,
  },

  search: {
    type: Boolean,
    default: false,
  },

  withRightText: {
    type: Boolean,
    default: false,
  },

  tabindex: {
    type: Number,
    default: 0,
  },

  // popoverContent: {
  //   type: Boolean,
  //   default: false,
  // },
})

const emit = defineEmits([
  'update:modelValue',
  'errorTrigger',
  'onIconClick',
  'onBlur',
  'keydown',
])

const focused = ref(false)
const isPasswordVisible = ref(false)

const inputValue = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    // const val = props.type === 'number' ? getValueForNumber(next) : next
    emit('update:modelValue', value)
  },
})

const labelActive = computed(() => {
  if (!inputValue.value) {
    return false
  }

  const isValueEmpty = inputValue.value.toString().length === 0
  return !isValueEmpty || focused.value
})

const isPasswordType = computed(() => props.type === 'password')

const canShowControls = computed(
  () => isPasswordType.value || props.copy || props.icon || props.withRightText
)

function clearValue() {
  inputValue.value = null
}

function togglePasswordVisibility() {
  isPasswordVisible.value = !isPasswordVisible.value
}

function onClick() {
  emit('onIconClick')
}

// onCopySuccess() {
//   this.$toast.success(this.$t('TOAST.COPY_SUCCESS'))
// },
//
// onCopyError() {
//   this.$toast.error(this.$t('TOAST.COPY_ERROR'))
// },

function errorTrigger(e) {
  emit('errorTrigger', e)
}

function onFocus() {
  focused.value = true
}

function onInput(event) {
  const input = event.target
  inputValue.value = input.value
}

function onBlur(event) {
  const input = event.target

  input.value = inputValue.value?.toString() || ''
  focused.value = false
  emit('onBlur', input.value)
}

function handleKeydown(event) {
  emit('keydown', event)
}
</script>

<template>
  <!--  <VPopover-->
  <!--    :is="wrapperComponent"-->
  <!--    :disabled="!model"-->
  <!--    :class="{ 'field-wrapper_hover': popoverContent && model }"-->
  <!--    class="field-wrapper"-->
  <!--  >-->
  <div :class="{ 'field-wrapper_hover': inputValue }" class="field-wrapper">
    <BaseField
      v-bind="$attrs"
      :label-active="labelActive"
      :tooltip="props.tooltip"
      :disabled="props.disabled"
      :no-border="props.noBorder"
      :class="{ input_disabled: props.disabled }"
      class="input"
      @errorTrigger="errorTrigger"
    >
      <div class="input__label">
        <div
          v-if="props.label"
          class="input__label-component"
          :class="{ 'is-animated': !focused && !inputValue }"
        >
          {{ props.label }}
          <b v-if="props.required">*</b>
        </div>

        <div class="input__content">
          <div
            v-if="props.search"
            class="input__controls-item search-icon"
            @click="onClick"
          >
            <svg :class="{ rotating: props.loading }">
              <use v-if="loading" href="#icon-loading" />
              <use v-else href="#icon-search" />
            </svg>
          </div>
          <input
            :value="inputValue"
            :type="isPasswordVisible ? 'text' : props.type"
            :tabindex="props.tabindex"
            :readonly="props.disabled"
            :placeholder="
              typeof props.placeholder === 'string' ? props.placeholder : null
            "
            :class="{
              input__field_placeholder: !!props.placeholder,
              'input__field_placeholder-icon':
                props.placeholder && props.search,
            }"
            class="input__field"
            @input="onInput"
            @focus="onFocus"
            @blur.prevent="onBlur"
            @keydown="handleKeydown"
          />

          <div
            v-if="canShowControls"
            class="input__controls"
            @click.stop.prevent
          >
            <div
              v-if="props.clearable && inputValue"
              class="input__icons"
              @click="clearValue"
            >
              <svg class="input__icon-clear">
                <use href="#icon-close" />
              </svg>
            </div>

            <div v-if="props.icon" class="input__icons" @click="onClick">
              <svg class="input__icon">
                <use :href="`#icon-${props.icon}`" />
              </svg>
            </div>

            <div
              v-if="isPasswordType"
              class="input__controls-item eye-icon"
              @click="togglePasswordVisibility"
            >
              <div class="eye-icon__container">
                <svg v-if="isPasswordVisible">
                  <use href="#icon-eye-opened" />
                </svg>
                <svg v-else>
                  <use href="#icon-eye-closed" />
                </svg>
              </div>
            </div>

            <div v-if="props.withRightText" class="input__right-text">
              <slot name="text"></slot>
            </div>

            <!--            <div-->
            <!--              v-if="copy"-->
            <!--              v-clipboard:copy="model"-->
            <!--              v-clipboard:success="onCopySuccess"-->
            <!--              v-clipboard:error="onCopyError"-->
            <!--              class="input__controls-item copy-icon"-->
            <!--            >-->
            <!--              <div class="copy-icon__container">-->
            <!--                <svg><use href="#icon-copy" /></svg>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </BaseField>

    <!--    <template v-if="popoverContent" #popover>-->
    <!--      <div class="field-wrapper__popover">-->
    <!--        {{ model }}-->
    <!--      </div>-->
    <!--    </template>-->
  </div>
</template>

<style lang="scss" scoped>
@import 'base-input';
</style>
