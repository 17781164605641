import Vue3Toasity from 'vue3-toastify'

export default {
  install: (app) => {
    app.use(Vue3Toasity, {
      autoClose: 5000,
      icon: true,
      hideProgressBar: true,
      theme: 'colored',
    })
  },
}
