import { PostApiRequest } from '@/plugins/api/base'

export class RestorePasswordByPhone extends PostApiRequest {
  constructor(context, data = {}) {
    const body = {
      NewPassword: data.password,
      VerificationCode: data.verificationCode,
      PhoneNumber: data.phoneNumber,
    }

    const url = '/api/account/restorePasswordByPhone'
    super(context, url, body)
  }
}
