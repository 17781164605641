import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import { Workbox } from 'workbox-window'
import Api from '@/plugins/api'
import i18n from '@/plugins/i18n'
import ScrollLock from '@/plugins/scroll-lock/index'
import ModalsPlugin from '@/plugins/modal/index'
import toast from '@/plugins/toastification'
import Popper from 'vue3-popper'
import './registerServiceWorker'

import 'vue3-toastify/dist/index.css'
import '@/assets/scss/main.scss'

const app = createApp(App)

// Configure Vue app with HTTPS, host, and port // TODO check
// app.config.globalProperties.$https = true
// app.config.globalProperties.$host = 'http://dev.shown.win'
// app.config.globalProperties.$port = 7731

app.use(Api)
app.use(toast)
app.use(createPinia())
app.use(router)
app.component('Popper', Popper)
app.use(i18n)
app.use(ScrollLock)
app.use(ModalsPlugin)

// Register the service worker
if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
  const workbox = new Workbox('/service-worker.js')

  console.log(workbox, 'workbox log')

  workbox.addEventListener('waiting', (event) => {
    console.warn('New version available')

    // Prompt the user to update
    if (confirm('A new version is available. Do you want to update? (v1)')) {
      workbox.addEventListener('controlling', (event) => {
        // Reload the page to activate the new version
        window.location.reload()
      })

      // Skip waiting to activate the new version immediately
      workbox.messageSW({ type: 'SKIP_WAITING' })
    }
  })

  // Register the service worker
  workbox
    .register()
    .then(() => {
      // Service worker registration is successful
      console.log('Service worker registration is successful - TEST 1')
      app.mount('#app')
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error)
    })
} else {
  console.log('development build')
  app.mount('#app')
}
