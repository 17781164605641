import { GetApiRequest } from '@/plugins/api/base'

export class GetUserBalance extends GetApiRequest {
  constructor(context) {
    const body = {
      Culture: context.i18n.locale.value,
    }

    const url = `/Bsw/BCService.asmx/GetUserBalance`
    super(context, url, body)
  }
}
