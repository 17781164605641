import { defineAsyncComponent } from 'vue'

export function useModalComponents() {
  return {
    TestModal: {
      component: defineAsyncComponent(() =>
        import('@/components/modals/TestModal.vue')
      ),
    },
    InfoModal: {
      component: defineAsyncComponent(() =>
        import('@/components/modals/info-modal/InfoModal.vue')
      ),
    },
    SearchModal: {
      component: defineAsyncComponent(() =>
        import('@/components/modals/search-modal/SearchModal.vue')
      ),
      wrapperOptions: {
        halfScreen: true,
      },
    },
    CalculatorModal: {
      component: defineAsyncComponent(() =>
        import('@/components/modals/calculator-modal/CalculatorModal.vue')
      ),
      wrapperOptions: {
        withBorder: true,
      },
    },
    SettingsModal: {
      component: defineAsyncComponent(() =>
        import('@/components/modals/settings-modal/SettingsModal.vue')
      ),
      wrapperOptions: {
        withBorder: true,
      },
    },
    CouponSettingsModal: {
      component: defineAsyncComponent(() =>
        import(
          '@/components/modals/coupon-settings-modal/CouponSettingsModal.vue'
        )
      ),
    },
    TotalsModal: {
      component: defineAsyncComponent(() =>
        import('@/components/modals/totals-modal/TotalsModal.vue')
      ),
      wrapperOptions: {
        noPadding: true,
      },
    },
    HandicapModal: {
      component: defineAsyncComponent(() =>
        import('@/components/modals/handicap-modal/HandicapModal.vue')
      ),
      wrapperOptions: {
        noPadding: true,
      },
    },
    ChangePasswordModal: {
      component: defineAsyncComponent(() =>
        import(
          '@/components/modals/change-password-modal/ChangePasswordModal.vue'
        )
      ),
      wrapperOptions: {
        noPadding: true,
      },
    },
    HistoryFilterModal: {
      component: defineAsyncComponent(() =>
        import(
          '@/components/modals/history-filter-modal/HistoryFilterModal.vue'
        )
      ),
      wrapperOptions: {
        noPadding: true,
        gradientHeight: 16,
      },
    },
  }
}
