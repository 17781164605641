class TokenService {
  getLocalRefreshToken() {
    return localStorage.getItem('refresh_token')
  }

  getLocalAccessToken() {
    return localStorage.getItem('access_token')
  }

  updateLocalRefreshToken(token) {
    localStorage.setItem('refresh_token', token)
    return token
  }

  updateLocalAccessToken(token) {
    localStorage.setItem('access_token', token)
    return token
  }

  removeRefreshToken() {
    localStorage.removeItem('refresh_token')
  }

  removeAccessToken() {
    localStorage.removeItem('access_token')
  }
}

export default new TokenService()
