import { createI18n } from 'vue-i18n'
import enUS from '@/locales/en-US'
import ruRU from '@/locales/ru-RU'
import ukUA from '@/locales/uk-UA'

const ENGLISH_LOCALE = 'en-US'
const RUSSIAN_LOCALE = 'ru-RU'
const UKRAINIAN_LOCALE = 'uk-UA'

export const DEFAULT_LOCALE = ENGLISH_LOCALE
const CURRENT_APP_LANGUAGE = 'current-app-language'

const messages = {
  [ENGLISH_LOCALE]: enUS,
  [RUSSIAN_LOCALE]: ruRU,
  [UKRAINIAN_LOCALE]: ukUA,
}

function getDefaultLocale() {
  const storageLocale = localStorage.getItem(CURRENT_APP_LANGUAGE)
  const value = Object.keys(messages).find((item) => item === storageLocale)

  return value || DEFAULT_LOCALE
}

const i18n = createI18n({
  locale: getDefaultLocale(), // Default locale
  legacy: false,
  messages,
})

export default i18n
