<script setup>
import { defineProps, nextTick } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Autoplay } from 'swiper/modules'

// import 'swiper/css/bundle'
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/navigation'

const props = defineProps({
  swiperOption: {
    type: Object,
    default: () => ({
      slidesPerView: 'auto',
      spaceBetween: 14,
      autoplay: false,
      loop: false,
      navigation: false,
      visible: true,
    }),
  },
  items: {
    type: Array,
    required: true,
  },
  topItems: {
    type: [Array, Boolean],
    default: null,
  },
})

const modules = [Navigation, Autoplay]

function updated(instance) {
  nextTick(() => {
    instance.update()
  })
}
</script>

<template>
  <Swiper
    :slides-per-view="props.swiperOption.slidesPerView"
    :space-between="props.swiperOption.spaceBetween"
    :loop="props.swiperOption.loop"
    :autoplay="props.swiperOption.autoplay"
    :navigation="props.swiperOption.navigation"
    :modules="modules"
    class="base-swiper"
    @init="updated"
  >
    <template v-if="topItems">
      <template v-if="topItems.length">
        <SwiperSlide
          v-for="(item, index) in topItems"
          :key="index"
          class="base-swiper__slide"
        >
          <slot name="top" :item="item" :index="index" />
        </SwiperSlide>
      </template>

      <SwiperSlide v-else class="base-swiper__slide">
        <slot name="top" />
      </SwiperSlide>
    </template>

    <SwiperSlide
      v-for="(item, index) in items"
      :key="index"
      class="base-swiper__slide"
    >
      <slot :item="item" :index="index" />
    </SwiperSlide>
  </Swiper>
</template>

<style lang="scss" scoped>
@import 'base-swiper';
</style>
