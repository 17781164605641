<script setup>
import BaseLogo from '@/components/base/base-logo/BaseLogo.vue'
import BaseButton from '@/components/base/base-button/BaseButton.vue'
import LanguageSelector from '@/components/general/selectors/language-selector/LanguageSelector.vue'
import CardRound from '@/components/general/cards/card-round/CardRound.vue'
import BaseExpand from '@/components/base/base-expand/BaseExpand'
import BaseIcon from '@/components/base/base-icon/BaseIcon.vue'

import { defineEmits, defineProps, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useApiPlugin } from '@/plugins/api'

const props = defineProps({
  isLogged: {
    type: Boolean,
    default: false,
  },
  showMenu: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['toggleMenu'])

const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const api = useApiPlugin()

const menuItems = [
  {
    icon: 'home',
    name: 'GENERAL.HOME',
    link: '/',
  },
  {
    icon: 'live',
    name: 'GENERAL.LIVE',
    link: '/sport/live',
  },
  {
    icon: 'pre-match',
    name: 'GENERAL.PRE_MATCH',
    link: '/sport/pre-match',
  },
  {
    icon: 'games',
    name: 'GENERAL.GAMES',
    link: '/games',
  },
  {
    icon: 'results',
    name: 'GENERAL.RESULTS',
    link: '/results',
  },
  {
    icon: 'support',
    name: 'GENERAL.SUPPORT',
    link: '/support',
  },
]
const infoPages = ref([])

const allAvailableContentPages = ref([
  {
    ID: 1,
    Name: "About Us",
    UrlPath: "aboutus",
  },
  {
    ID: 2,
    Name: "Terms of Use",
    UrlPath: "termsofuse",
  },
  {
    ID: 3,
    Name: "Account Rules",
    UrlPath: "account_rules",
  },
  {
    ID: 4,
    Name: "Promotions",
    UrlPath: "promotions",
  },
  {
    ID: 5,
    Name: "Payment",
    UrlPath: "payment",
  },
])

onMounted(getContentPages)

async function getContentPages() {
  try {
    // const res = await api.logic.content.getAllAvailableContentPagesAsTree()
    // infoPages.value = res.Data[0]
    infoPages.value = allAvailableContentPages.value
  } catch (e) {
    console.error(e)
  }
}

function logout() {
  console.log('Logout')
}

function checkActive(link) {
  return route.fullPath === link
}

function goTo(link) {
  router.push(link)

  openedListInfoPages.value = false

  toggleMenu()
}

function toggleMenu() {
  emit('toggleMenu')
}

const menuFixedRef = ref(null)
const menuStylesToChange = {
  'overflow-x': 'visible',
  'overflow-y': 'visible',
}

const openedListInfoPages = ref(false)

function toggleInfoPages() {
  openedListInfoPages.value = !openedListInfoPages.value
}
</script>

<template>
  <div class="mobile-menu">
    <transition name="fade">
      <div
        v-if="props.showMenu"
        ref="menuFixedRef"
        class="mobile-menu__content"
        :class="{ active: props.showMenu }"
      >
        <div class="mobile-menu__top top">
          <div class="top__close" @click="toggleMenu">
            <svg class="top__close-icon">
              <use href="#icon-close" />
            </svg>
          </div>

          <div class="top__logo">
            <BaseLogo size="md" />
          </div>

          <div class="top__language">
            <LanguageSelector
              :fixed-parent-element="menuFixedRef"
              :styles-need-to-rollback="menuStylesToChange"
            />
          </div>
        </div>

        <div class="mobile-menu__options options">
          <div class="options__deposit" v-if="props.isLogged">
            <BaseButton
              size="full"
              class="options__btn"
              @click="goTo('/profile/deposits')"
            >
              <div class="options__btn-container">
                <div>{{ t('GENERAL.DEPOSIT') }}</div>
                <div>{{ t('GENERAL.GO') }}</div>
              </div>
            </BaseButton>
          </div>

          <div class="options__container">
            <div
              v-for="item in menuItems"
              :key="item.name"
              class="options__item"
              @click="goTo(item.link)"
            >
              <CardRound
                :icon="item.icon"
                :is-active="checkActive(item.link)"
                size="sm"
                variant="option"
                class="options__item-icon"
              />
              <div
                :class="{ active: checkActive(item.link) }"
                class="options__item-text"
              >
                {{ t(item.name) }}
              </div>
            </div>

            <BaseExpand
              :active="openedListInfoPages"
              class="options__expand"
              @click="toggleInfoPages"
            >
              <template #activator>
                <div class="options__item">
                  <CardRound
                    icon="info"
                    :is-active="route.name === 'documents'"
                    size="sm"
                    variant="option"
                    class="options__item-icon"
                  />

                  <div
                    :class="{ active: route.name === 'documents' }"
                    class="options__item-text"
                  >
                    {{ t('GENERAL.INFO') }}
                  </div>

                  <BaseIcon
                    :class="{ active: openedListInfoPages }"
                    class="options__expand-activator"
                    icon="icon-chevron-down"
                  />
                </div>
              </template>
              <template #content>
                <div class="options__expand-links">
                  <RouterLink
                    v-for="link in infoPages"
                    :key="link.ID"
                    :to="`/info/${link.UrlPath}`"
                    :class="{ active: checkActive(`/info/${link.UrlPath}`) }"
                    class="options__expand-link"
                    @click="toggleMenu"
                  >
                    {{ link.Name }}
                  </RouterLink>
                </div>
              </template>
            </BaseExpand>
          </div>

          <div v-if="props.isLogged" class="options__controls">
            <div class="options__user-data">
              <CardRound
                icon="user"
                size="sm"
                variant="option"
                class="options__user-data-icon"
              />
              <div class="options__user-data-id">ID 1234567</div>
              <div class="options__user-data-balance">6M USD</div>
            </div>

            <div class="options__logout" @click="logout">
              {{ t('AUTH.LOGOUT') }}
              <svg class="options__logout-icon">
                <use href="#icon-logout" />
              </svg>
            </div>
          </div>

          <div v-else class="options__auth">
            <BaseButton
              tag="RouterLink"
              to="/login"
              variant="secondary"
              size="full"
              class="options__btn"
            >
              <div class="options__btn-container">
                <div>{{ t('AUTH.LOGIN') }}</div>
                <svg class="options__btn-container-icon">
                  <use href="#icon-user" />
                </svg>
              </div>
            </BaseButton>

            <BaseButton
              tag="RouterLink"
              to="/registration"
              size="full"
              class="options__btn"
            >
              <div class="options__btn-container black">
                <div>{{ t('AUTH.REGISTRATION') }}</div>
                <div class="options__btn-container_go">{{ t('GENERAL.GO') }}</div>
              </div>
            </BaseButton>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
@import 'mobile-menu';
</style>
