import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/index.vue'),
    meta: {
      pageTitle: 'GENERAL.HOME',
      layout: 'default',
    },
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/Test.vue'),
    meta: {
      layout: 'default',
    },
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search/index.vue'),
    meta: {
      layout: 'default',
      pageTitle: 'GENERAL.SEARCH',
    },
  },
  {
    path: '/games',
    name: 'games',
    component: () => import('../views/games/index.vue'),
    meta: {
      layout: 'default',
      pageTitle: 'GENERAL.GAMES',
    },
    children: [
      {
        path: ':provider',
        name: 'providers',
        component: () => import('../views/games/index.vue'),
        meta: {
          pageTitle: 'GENERAL.PROVIDERS',
        },
      },
    ],
  },
  {
    path: '/bonuses',
    name: 'bonuses',
    component: () => import('../views/bonuses/index.vue'),
    meta: {
      layout: 'default',
      pageTitle: 'GENERAL.BONUSES',
    },
  },
  {
    path: '/results',
    name: 'results',
    component: () => import('../views/results/index.vue'),
    meta: {
      layout: 'default',
      pageTitle: 'GENERAL.RESULTS',
    },
  },
  {
    path: '/info',
    name: 'info',
    meta: {
      layout: 'default',
    },
    component: () => import('../views/info/index.vue'),
    children: [
      {
        path: ':document',
        name: 'documents',
        component: () => import('../views/info/index.vue'),
      },
    ],
  },
  {
    path: '/favourites',
    name: 'favourites',
    redirect: '/favourites/games',
    meta: {
      layout: 'default',
      pageTitle: 'GENERAL.FAVOURITES',
    },
    children: [
      {
        path: '/favourites/games',
        name: 'favourites-games',
        component: () => import('../views/favourites/index.vue'),
      },
      {
        path: '/favourites/events',
        name: 'favourites-events',
        component: () => import('../views/favourites/index.vue'),
      },
    ],
  },
  {
    path: '/sport',
    name: 'sport',
    // redirect: '/sport/pre-match',
    meta: {
      layout: 'default',
    },
    component: () => import('../views/sport-lines/index/index.vue'),
    children: [
      {
        path: '/sport/pre-match',
        name: 'pre-match',
        component: () =>
          import('../views/sport-lines/pre-match/index/index.vue'),
      },
      {
        path: '/sport/pre-match/countries/:sport',
        name: 'countries',
        component: () =>
          import('../views/sport-lines/pre-match/countries/index.vue'),
        props: true,
      },
      {
        path: '/sport/pre-match/tournament/:sport/:country/:tourney',
        name: 'tournament',
        component: () =>
          import('../views/sport-lines/pre-match/tournament/index.vue'),
      },
      {
        path: '/sport/pre-match/country-tournament/:sport/:country',
        name: 'country-tournament',
        component: () =>
          import('../views/sport-lines/pre-match/country-tournament/index.vue'),
      },
      {
        path: '/sport/live',
        name: 'live',
        component: () => import('@/views/sport-lines/live/index/index.vue'),
      },
      {
        path: '/sport/live/:sport',
        name: 'live-sport',
        component: () => import('@/views/sport-lines/live/index/index.vue'),
      },
      {
        path: '/sport/live/:sport/:country/:tournament',
        name: 'live-sport-tournament',
        component: () => import('@/views/sport-lines/live/index/index.vue'),
      },
    ],
  },
  {
    path: '/event-line/:id',
    name: 'event-line-id',
    meta: {
      layout: 'default',
    },
    component: () => import('../views/event-line/index/index.vue'),
    children: [
      {
        path: '/event-line/:id/:tab',
        name: 'event-line-tab',
        component: () => import('../views/event-line/_id/index.vue'),
      },
    ],
  },
  {
    path: '/event-live',
    name: 'event-live',
    meta: {
      layout: 'default',
    },
    component: () => import('../views/event-live/index/index.vue'),
    children: [
      {
        path: '/event-live/:id',
        name: 'event-live-id',
        component: () => import('../views/event-live/_id/index.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('../views/registration/index.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/recovery',
    name: 'recovery',
    component: () => import('../views/recovery/index.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    redirect: '/profile/basic-information',
    meta: {
      layout: 'default',
    },
    component: () => import('../views/profile/index/index.vue'),
    children: [
      {
        path: '/profile/basic-information',
        name: 'basic-information',
        component: () => import('../views/profile/basic-information/index.vue'),
      },

      {
        path: '/profile/bats',
        name: 'bats',
        component: () => import('../views/profile/bats/index.vue'),
      },

      {
        path: '/profile/bonuses',
        name: 'profile-bonuses',
        component: () => import('../views/profile/bonuses/index.vue'),
      },

      {
        path: '/profile/deposits',
        name: 'deposits',
        component: () => import('../views/profile/deposits/index.vue'),
      },

      {
        path: '/profile/payments',
        name: 'payments',
        component: () => import('../views/profile/payments/index.vue'),
      },

      {
        path: '/profile/transactions',
        name: 'transactions',
        component: () => import('../views/profile/transactions/index.vue'),
      },
    ],
  },
  {
    path: '/messages',
    name: 'messages',
    meta: {
      layout: 'default',
    },
    component: () => import('../views/messages/index/index.vue'),
  },
  {
    path: '/messages/:id',
    name: 'messages-id',
    meta: {
      layout: 'default',
    },
    component: () => import('../views/messages/_id/index.vue'),
  },
  {
    path: '/error',
    component: () => import('../views/error/index.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../views/_/index.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
