<script setup>
import BaseSwiper from '@/components/base/base-swiper/BaseSwiper.vue'

import { useRoute, useRouter } from 'vue-router'
import { computed, defineProps, ref, onBeforeUnmount, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  pages: {
    type: Array,
    default: () => [],
  },
  tabs: {
    type: Array,
    default: () => [],
  },
  mainPath: {
    type: String,
    default: '',
  },
  tag: {
    type: String,
    default: 'RouterLink',
    validator(value) {
      return ['button', 'a', 'RouterLink'].includes(value)
    },
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  getActivePage: {
    type: Function,
    default: null,
    required: false,
  },
  queryName: {
    type: String,
    default: 'tab',
  },
  withQuantity: {
    type: Boolean,
    default: false,
  },
  swiperOption: {
    type: Object,
    default: null,
    required: false,
  },
})

const router = useRouter()
const route = useRoute()
const { t } = useI18n()

const isLocalTabs = computed(() => props.tag === 'button')

const pageTabs = computed(() => (isLocalTabs.value ? props.tabs : props.pages))

const routePage = computed(() => {
  const splitted = route.path.split('/')
  const routePage = splitted[splitted.length - 1]
  return routePage ? routePage : ''
})

// TODO script for diff k
onMounted(() => {
  if (props.tabs.length) {
    if (props.queryName !== 'tab') {
      changeTab(props.tabs[0].link)
    }
  }
})

onBeforeUnmount(changeTab)

function pageLink(item) {
  if (isLocalTabs.value) {
    return ''
  } else if (item.redirect) {
    return item.redirect
  } else {
    return `/${props.mainPath}/${item.link}`
  }
}

function changeTab(link) {
  if (isLocalTabs.value) {
    // TODO correct for another filters
    link
      ? router.push({ query: { ...route.query, [props.queryName]: link } })
      : router.push({ query: { ...route.query, [props.queryName]: undefined } })
  }
}

function activePage(page) {
  if (props.getActivePage) {
    return props.getActivePage(page)
  }

  return isLocalTabs.value
    ? route.query[props.queryName] === page.link
    : route.path.includes(page.link)
}

const tabPaddings = computed(() => {
  const itemsLength = props.tabs.length || props.pages.length
  if (itemsLength > 3) {
    return 'md'
  }

  return 'xs'
})

const defaultSwiperOption = ref({
  slidesPerView: 'auto',
  spaceBetween: 4,
})

const swiperOptions = computed(() => {
  return props.swiperOption ? props.swiperOption : defaultSwiperOption.value
})
</script>

<template>
  <div class="tabs">
    <div class="tabs__list">
      <div class="tabs__list-container">
        <div class="tabs__list-wrapper">
          <BaseSwiper :swiper-option="swiperOptions" :items="pageTabs">
            <template #default="{ item }">
              <div
                :key="item.link"
                :class="{
                  ['tabs__item_active']: activePage(item),
                  ['tabs__item_disabled']: item.disabled,
                }"
                class="tabs__item"
              >
                <div class="tabs__item-container">
                  <div class="tabs__item-corner tabs__item-corner_left" />

                  <Component
                    :is="props.tag"
                    :to="pageLink(item)"
                    :disabled="props.disabled"
                    :class="{
                      [`tabs__item-link_paddings-${tabPaddings}`]: true,
                    }"
                    class="tabs__item-link"
                    @click="changeTab(item.link)"
                  >
                    {{ t(item.name) }}
                    <span v-if="props.withQuantity"> {{ item.quantity }}</span>
                  </Component>

                  <div class="tabs__item-corner tabs__item-corner_right" />
                </div>
              </div>
            </template>
          </BaseSwiper>
        </div>
      </div>

      <slot name="actions" />
    </div>

    <div class="tabs__content">
      <div v-if="isLocalTabs">
        <slot name="tabs" />
      </div>

      <RouterView v-else v-slot="{ Component }">
        <Suspense>
          <template #default>
            <slot :component="Component" :key="routePage" name="component">
              <component :is="Component" :key="routePage" class="tabs__page" />
            </slot>
          </template>

          <template #fallback> Loading... </template>
        </Suspense>
      </RouterView>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'base-page-tabs';
</style>
