export class ScrollLockCustomPlugin {
  constructor() {
    this.disabledCounter = 0
  }

  async disableScroll() {
    if (!window || !window.location || !window.top) {
      return
    }

    let scrollY
    try {
      if (window.top[0].scrollY) {
        scrollY = await window.top[0].scrollY
      } else {
        scrollY = await window.top.scrollY
      }
    } catch {
      if (window.top.scrollY !== undefined) {
        scrollY = await window.top.scrollY
      }
    }

    const body = document.body
    body.style.position = 'fixed'
    // body.style.paddingRight = `${window.innerWidth - body.clientWidth}px`
    body.style.top = `-${scrollY}px`
    body.style.bottom = `0`
    body.style.width = '100%'
    body.style.overflow = 'hidden'
    this.disabledCounter++
  }

  enableScroll() {
    this.disabledCounter--
    if (this.disabledCounter >= 1) {
      return
    }

    const body = document.body
    const scrollY = body.style.top
    body.style.paddingRight = ''
    body.style.overflow = ''
    body.style.position = ''
    body.style.top = ''
    body.style.bottom = ''
    body.style.width = ''
    window.scrollTo(0, parseInt(scrollY || '0') * -1)
  }
}
