<script setup>
import { computed, defineProps } from 'vue'

const props = defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: null,
  },
  image: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
  to: {
    type: String,
    default: '',
  },
  variant: {
    type: String,
    default: 'sport',
    validator(value) {
      return ['sport', 'option', 'simple', 'black', 'white'].includes(value)
    },
  },
  color: {
    type: String,
    default: '',
    validator(value) {
      return [
        'green',
        'blue',
        'orange',
        'red',
        'violet',
        'gray',
        'white',
        'gold',
        '',
      ].includes(value)
    },
    required: false,
  },
  size: {
    type: String,
    default: 'md',
    validator(value) {
      return ['xs', 'sm', 'md', 'lg'].includes(value)
    },
  },
  fontSize: {
    type: String,
    default: '12',
  },
})

const classes = computed(() => ({
  [`card-round_variant-${props.variant}`]: true,
  [`card-round_color-${props.color}`]: props.color,
  [`card-round_size-${props.size}`]: true,
  ['card-round_active']: props.isActive,
}))
</script>

<template>
  <RouterLink :to="props.to" class="card-round__wrapper">
    <div class="card-round" :class="classes">
      <svg v-if="props.icon" class="card-round__icon">
        <use :href="`#icon-${props.icon}`" />
      </svg>
      <img
        v-else-if="props.image"
        :src="props.image"
        class="card-round__image"
        alt=""
      />
    </div>

    <div
      v-if="props.title"
      class="card-round__title"
      :class="{ [`card-round__title_size-${props.size}`]: true }"
      :style="{ 'font-size': `${props.fontSize}px` }"
    >
      {{ props.title }}
    </div>
  </RouterLink>
</template>

<style lang="scss" scoped>
@import 'card-round';
</style>
