<script setup>
import BaseField from '@/components/base/base-field/BaseField'
import BaseIcon from '@/components/base/base-icon/BaseIcon'
import BaseRadioButton from '@/components/base/base-radio-button/BaseRadioButton.vue'

import {
  computed,
  defineEmits,
  defineProps,
  inject,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
} from 'vue'
import useProps from '@/components/base/base-select/use-props'
import { useFixedParentDetect } from '@/components/base/base-select/use-fixed-parent-detect'

const props = defineProps({
  modelValue: {
    type: [String, Number, Object],
    default: null,
  },

  options: {
    type: Array,
    default: () => [],
    required: true,
  },

  placeholder: {
    type: String,
    default: null,
  },

  tooltip: {
    type: String,
    default: null,
  },

  required: {
    type: Boolean,
    default: false,
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  clearable: {
    type: Boolean,
    default: false,
  },

  hideActivator: {
    type: Boolean,
    default: false,
  },

  loading: {
    type: Boolean,
    default: false,
  },

  noBorder: {
    type: Boolean,
    default: false,
  },

  noPadding: {
    type: Boolean,
    default: false,
  },

  hidePlaceholder: {
    type: Boolean,
    default: false,
  },

  search: {
    type: Boolean,
    default: false,
  },

  reduce: {
    type: Function,
    default: (option) => option,
  },

  getOptionLabel: {
    type: Function,
    default: (option) => option,
  },

  fixedParentElement: {
    type: HTMLElement,
    default: null,
  },

  stylesNeedToRollback: {
    type: Array,
    default: () => [],
    required: false,
  },

  optionsTitle: {
    type: String,
    default: 'Title',
  },

  closeOnUpdate: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue', 'errorTrigger'])

const scrollLockCustomPlugin = inject('scrollLockCustomPlugin')

const { getOptionLabel: getLabel, label } = useProps(props)
const { prevElementStyles, revertStyles, setNewStyles, getComputedStyleCopy } =
  useFixedParentDetect({
    parentElementGetter: () => props.fixedParentElement,
    newStyles: props.stylesNeedToRollback,
  })

function errorTrigger(e) {
  emit('errorTrigger', e)
}

const focused = ref(false)
const optionsOpened = ref(false)
const showOptions = ref(false)
const optionsRef = ref(null)

const inputValue = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)

    if (props.closeOnUpdate) {
      toggleOptions()
    }
  },
})

const labelActive = computed(() => {
  if (!inputValue.value) {
    return false
  }

  const isValueEmpty = inputValue.value.toString().length === 0
  return !isValueEmpty || focused.value
})

function toggleOptions() {
  if (!showOptions.value) {
    showOptions.value = true

    setTimeout(() => {
      optionsOpened.value = true
      scrollLockCustomPlugin.disableScroll()
      setNewStyles()
    }, 50)
  } else {
    optionsOpened.value = false
    setTimeout(() => {
      showOptions.value = false
      scrollLockCustomPlugin.enableScroll()
      revertStyles()
    }, 300)
  }

  // setTimeout(() => {
  //   // optionsOpened.value = !optionsOpened.value
  //   if (!optionsOpened.value) {
  //     scrollLockCustomPlugin.enableScroll()
  //     revertStyles()
  //   } else {
  //     scrollLockCustomPlugin.disableScroll()
  //     setNewStyles()
  //   }
  // })
}
function setOption(option) {
  inputValue.value = option
}

onMounted(async () => {
  await nextTick(() => {
    if (props.fixedParentElement) {
      prevElementStyles.value = getComputedStyleCopy(props.fixedParentElement)
    }
  })

  resizeOptionsWidth()
  window.addEventListener('resize', resizeOptionsWidth, false)
})

onUnmounted(() => {
  window.removeEventListener('resize', resizeOptionsWidth, false)
})

function resizeOptionsWidth() {
  optionsRef.value.style.width = `${document.body.clientWidth}px`
}
</script>

<template>
  <div :class="{ 'field-wrapper_hover': inputValue }" class="field-wrapper">
    <BaseField
      v-bind="$attrs"
      :label-active="labelActive"
      :tooltip="props.tooltip"
      :disabled="props.disabled"
      :no-border="props.noBorder"
      :class="{ input_disabled: props.disabled }"
      class="select"
      @errorTrigger="errorTrigger"
    >
      <div
        :class="[{ 'select__container_no-padding': props.noPadding }]"
        class="select__container"
        @click="toggleOptions"
      >
        <div class="select__selected">
          <span
            v-if="props.placeholder"
            :class="[
              { 'select__selected-placeholder_top': inputValue },
              { 'select__selected-placeholder_hidden': props.hidePlaceholder },
            ]"
            class="select__selected-placeholder"
          >
            {{ props.placeholder }}
          </span>

          <slot name="option-selected" :option="inputValue">
            <span class="select__selected-option">
              {{ getLabel(inputValue) }}
            </span>
          </slot>
        </div>

        <BaseIcon
          v-if="!props.hideActivator"
          icon="icon-chevron-down"
          class="select__icon"
        />
      </div>

      <div
        :class="{
          select__options_opened: optionsOpened,
          select__options_shown: showOptions,
        }"
        ref="optionsRef"
        class="select__options"
      >
        <div class="select__overlay" @click="toggleOptions"></div>

        <div class="select__list">
          <div class="select__list-head">
            <span class="select__list-title">{{ props.optionsTitle }}</span>

            <BaseIcon
              icon="icon-close"
              class="select__list-icon"
              @click="toggleOptions"
            />
          </div>

          <div class="select__list-container">
            <div class="select__list-wrapper">
              <div
                v-for="option in props.options"
                class="select__option"
                @click="setOption(option)"
              >
                <BaseRadioButton
                  :value="inputValue"
                  :option="option"
                  class="select__option-input"
                />

                <slot name="option-inner" :option="option">
                  <span class="select__option-title">
                    {{ getLabel(option) }}
                  </span>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseField>
  </div>
</template>

<style scoped lang="scss">
@import 'base-select';
</style>
