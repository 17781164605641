import { ref } from 'vue'

export function useFixedParentDetect({ parentElementGetter, newStyles }) {
  const listOfStylesToCopy = Object.keys(newStyles)
  const prevElementStyles = ref(null)

  function getComputedStyleCopy(element) {
    const computedStyles = {}

    const styles = window.getComputedStyle(element)

    for (let i = 0; i < styles.length; i++) {
      const propertyName = styles[i]
      if (listOfStylesToCopy.includes(propertyName)) {
        computedStyles[propertyName] = styles.getPropertyValue(propertyName)
      }
    }

    return computedStyles
  }

  function revertStyles() {
    if (!parentElementGetter()) {
      return
    }

    const styles = prevElementStyles.value
    for (const propertyName in styles) {
      if (Object.prototype.hasOwnProperty.call(styles, propertyName)) {
        parentElementGetter().style.setProperty(
          propertyName,
          styles[propertyName]
        )
      }
    }
  }

  function setNewStyles() {
    if (!parentElementGetter()) {
      return
    }

    for (const item in newStyles) {
      parentElementGetter().style.setProperty(item, newStyles[item])
    }
  }

  return {
    prevElementStyles,
    revertStyles,
    setNewStyles,
    getComputedStyleCopy,
  }
}
