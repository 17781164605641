export const betAmountOptions = [
  {
    amount: 2,
  },
  {
    amount: 5,
  },
  {
    amount: 10,
  },
]
