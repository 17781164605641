import { GetApiRequest } from '@/plugins/api/base'

export class GetLineTournamentsWithEvents extends GetApiRequest {
  constructor(context, data) {
    const culture = context.i18n.locale.value

    const url = `/api/line/${culture}/events/sport${data.sportId}/country${data.countryId}/tourney${data.tourneyId}/${data.startsWithin}`
    super(context, url)
  }
}
