<script setup>
import BaseSelect from '@/components/base/base-select/BaseSelect.vue'

import { useLanguageSelectorData } from '@/components/general/selectors/language-selector/use-language-selector-data'
import { useI18n } from 'vue-i18n'

const { selectedLanguage, LOCALES, getLangIcon } = useLanguageSelectorData()
const { t } = useI18n()
</script>

<template>
  <div class="lang-select">
    <BaseSelect
      v-bind="$attrs"
      v-model="selectedLanguage"
      :options="LOCALES"
      :get-option-label="(item) => item.Name"
      :options-title="t('GENERAL.SELECT_LANGUAGES')"
      no-border
      no-padding
      hide-activator
      class="lang-select__component"
    >
      <template #option-selected="{ option }">
        <div class="lang-select__selected">
          <img
            :src="getLangIcon(option)"
            alt="Flag"
            class="lang-select__selected-icon"
          />
        </div>
      </template>

      <template #option-inner="{ option }">
        <div
          :class="{
            'lang-select__inner_active':
              selectedLanguage.Culture === option.Culture,
          }"
          class="lang-select__inner"
        >
          <img
            :src="getLangIcon(option)"
            alt="Flag"
            class="lang-select__inner-icon"
          />

          <span class="lang-select__inner-name">{{ t(option.Name) }}</span>
        </div>
      </template>
    </BaseSelect>
  </div>
</template>

<style lang="scss">
@import '@/assets/scss/variables/colors';

.lang-select {
  &__component {
    width: fit-content;
    height: fit-content;
    &.field-wrapper {
      width: fit-content;
      height: fit-content;
    }
  }

  &__selected {
    display: flex;

    &-icon {
      width: 24px;
      height: 18px;
    }
  }

  &__inner {
    display: flex;
    grid-gap: 8px;

    &_active & {
      &-name {
        color: $gold-accent;
      }
    }

    &-icon {
      width: 21px;
      height: 15px;
    }

    &-name {
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
    }
  }
}
</style>
