<script setup>
import BaseButton from '@/components/base/base-button/BaseButton.vue'
import BaseTooltip from '@/components/base/base-tooltip/BaseTooltip.vue'
import BasePageTabs from '@/components/base/base-page-tabs/BasePageTabs.vue'
import BetInfoBlock from '@/components/general/the-coupon/bet-info-block/BetInfoBlock.vue'
import BaseIcon from '@/components/base/base-icon/BaseIcon.vue'
import BaseInput from '@/components/base/base-input/BaseInput.vue'
import BaseSelect from '@/components/base/base-select/BaseSelect.vue'
import BaseSwitcher from '@/components/base/base-switcher/BaseSwitcher.vue'

import {
  computed,
  defineEmits,
  defineProps,
  inject,
  onMounted,
  ref,
  watch,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import useCookieSetter from '@/composables/use-cookie-setter'
import { systemOptions } from '@/pkg/configs/system-options'
import { betAmountOptions } from '/public/configs/bet-amount-options'
import { useStateStore } from '@/store/state'

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['toggleCoupon'])

const router = useRouter()
const route = useRoute()
const { t } = useI18n()

const { readCookie } = useCookieSetter()

const stateStore = useStateStore()
const { lineCouponList, toggleLineCouponItem, isAddedLineCouponItem } =
  stateStore

const modalPlugin = inject('modalsPlugin')

const tabs = [
  {
    name: 'COUPON.SINGLE',
    link: 'single',
    disabled: true,
  },
  {
    name: 'COUPON.MULTI',
    link: 'multi',
    disabled: true,
  },
  {
    name: 'COUPON.SYSTEM',
    link: 'system',
    disabled: true,
  },
]

const data = lineCouponList

const coupon = ref([])
const couponCopy = ref([])

const oneClickBet = ref(false)
const timer = ref(null)
const betAmount = ref(null)
const totalOdds = ref(0)
const possibleWinnings = ref(0)
const systemOption = ref(systemOptions[0])

const betsCount = computed(() => coupon.value && coupon.value.length)
const isEmpty = computed(() => !(coupon.value && coupon.value.length))
const isCopyExist = computed(
  () =>
    !!(couponCopy.value && couponCopy.value.length) &&
    !(coupon.value && coupon.value.length)
)
const tab = computed(() => tabs.find((item) => item.link === route.query.tab))

watch(() => coupon.value && coupon.value.length, makeCopy, { immediate: true })
watch(() => props.show, changeSystemType)

onMounted(init)

function init() {
  coupon.value = data

  // makeCopy()
  const cookieMap = readCookie()
  const betAmountValue = cookieMap.betAmount
  betAmount.value = (betAmountValue && Number(betAmountValue)) || null
}

function deleteOdd(item) {
  if (!item) {
    return
  }

  toggleLineCouponItem(item)

  const matchItem = coupon.value.indexOf(item)
  if (matchItem !== -1) {
    coupon.value.splice(matchItem, 1)
    return
  }
}

function setBetAmount(amount) {
  betAmount.value = amount
}

function setSystemOptionLabel(option) {
  return option ? `${t('COUPON.SYSTEM')} ${option.name}` : ''
}

function openCalculatorModal() {
  modalPlugin.open({
    component: 'CalculatorModal',
  })
}

function openSettingsModal() {
  modalPlugin.open({
    component: 'CouponSettingsModal',
  })
}

function openInfoModal() {
  modalPlugin.open({
    component: 'InfoModal',
    options: {
      title: 'GENERAL.ATTENTION',
      description: 'COUPON.NOT_ENOUGH_FUNDS',
      btnText: 'COUPON.PAYMENT',
    },
  })
}

function clearAll() {
  if (!isEmpty.value) {
    coupon.value = []

    timer.value = setTimeout(() => {
      couponCopy.value = []
    }, 10000) // TODO correct time
  }
}

function cancel() {
  clearTimeout(timer.value)
  coupon.value = couponCopy.value
}

async function changeSystemType(tab = undefined) {
  let link = tab
  if (coupon.value.length === 1) {
    link = 'single'

    tabs.forEach((tab) => {
      tab.disabled = tab.link !== link
    })
  } else if (coupon.value.length === 2) {
    link = 'multi'

    tabs.forEach((tab) => {
      tab.disabled = tab.link !== 'multi'
    })
  } else if (coupon.value.length > 2) {
    link = 'system'

    tabs.forEach((tab) => {
      tab.disabled = tab.link === 'single'
    })
  }

  if (!props.show) {
    await router.push({ query: { ...route.query, tab: undefined } })
  } else if (link && route.query.tab !== link) {
    await router.push({ query: { ...route.query, tab: link } })
  }
}

function makeCopy() {
  if (!isEmpty.value) couponCopy.value = coupon.value

  changeSystemType()
}

function makeBet() {
  console.log('makeBet')

  // TODO check balance
  openInfoModal()
}

function goTo(link) {
  router.push(link)

  toggleCoupon()
}

function toggleCoupon() {
  emit('toggleCoupon')
}
</script>

<template>
  <div class="coupon">
    <transition name="fade">
      <div
        v-if="props.show"
        ref="content"
        class="coupon__content"
        :class="{ active: props.show }"
      >
        <div class="coupon__close">
          <div class="coupon__close-inner">
            <div class="coupon__close-container" @click="toggleCoupon">
              <div class="coupon__close-container-inner">
                <svg class="coupon__close-icon">
                  <use href="#icon-chevron-down-new" />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div class="coupon__top top">
          <div class="top__title">
            {{ t('COUPON.BETS') }}

            <div v-if="betsCount" class="top__badge messages-badge">
              {{ betsCount }}
            </div>
          </div>

          <div class="top__one-click">
            <BaseTooltip class="top__one-click-tooltip">
              <svg class="top__one-click-icon">
                <use href="#icon-help-circle" />
              </svg>

              <template #content>
                <div>
                  {{ t('COUPON.ONE_CLICK_TOOLTIP') }}
                </div>
              </template>
            </BaseTooltip>

            <div class="top__one-click-title">
              {{ t('COUPON.ONE_CLICK') }}
            </div>

            <BaseSwitcher
              v-model:value="oneClickBet"
              class="top__one-click-switcher"
            />
          </div>

          <div v-if="isEmpty || oneClickBet" class="top__bets-actions">
            <BaseIcon
              icon="icon-calculator"
              class="top__bets-actions-icon"
              @click="openCalculatorModal"
            />

            <BaseIcon
              icon="icon-settings"
              class="top__bets-actions-icon"
              @click="openSettingsModal"
            />
          </div>
        </div>

        <div class="coupon__container">
          <div v-if="isEmpty" class="coupon__empty">
            <img
              src="/imgs/group/bets.png"
              alt="bets"
              class="coupon__empty-image"
            />

            <div class="coupon__empty-title">
              {{ t('COUPON.PLACE_BET') }}
            </div>

            <div class="coupon__empty-description">
              {{ t('COUPON.EMPTY_DESCRIPTION') }}
            </div>
          </div>

          <div
            v-else-if="coupon && coupon.length && !oneClickBet"
            class="coupon__bets"
          >
            <BasePageTabs tag="button" :tabs="tabs">
              <template #actions>
                <div class="coupon__bets-actions">
                  <BaseIcon
                    icon="icon-calculator"
                    class="coupon__bets-actions-icon"
                    @click="openCalculatorModal"
                  />

                  <BaseIcon
                    icon="icon-settings"
                    class="coupon__bets-actions-icon"
                    @click="openSettingsModal"
                  />
                </div>
              </template>

              <template #tabs>
                <div v-if="tabs" class="coupon__bets-container">
                  <div class="coupon__bets-items">
                    <!--                    TODO return after fix coupon position-->
                    <!--                    <div-->
                    <!--                      v-if="tab && tab.link === 'system'"-->
                    <!--                      class="coupon__bets-system"-->
                    <!--                    >-->
                    <!--                      <BaseSelect-->
                    <!--                        v-model="systemOption"-->
                    <!--                        :options="systemOptions"-->
                    <!--                        :getOptionLabel="-->
                    <!--                          (option) => setSystemOptionLabel(option)-->
                    <!--                        "-->
                    <!--                        :placeholder="t('COUPON.SYSTEM')"-->
                    <!--                        class="coupon__bets-system-select"-->
                    <!--                      />-->
                    <!--                    </div>-->

                    <BetInfoBlock
                      v-for="item in coupon"
                      :key="item.id"
                      :info="item"
                      @delete-odd="deleteOdd(item)"
                    />

                    <div
                      v-if="tab && tab.link === 'multi'"
                      class="coupon__bets-total-odds"
                    >
                      {{ t('COUPON.TOTAL_ODDS') }}
                      <span class="coupon__bets-total-odds_bold">
                        {{ totalOdds.toFixed(2) }}
                      </span>
                    </div>
                  </div>

                  <div class="coupon__bets-amount">
                    <!-- TODO component (+settingsModal) -->
                    <BaseInput
                      v-model="betAmount"
                      :label="t('COUPON.BET_AMOUNT')"
                      with-right-text
                      class="coupon__bets-amount-input"
                    >
                      <template #text>
                        <span class="coupon__bets-amount-text">
                          USDT
                          <!-- TODO correct currency -->
                          <!-- {{ basicUserInfo.currency }}-->
                        </span>
                      </template>
                    </BaseInput>

                    <div
                      v-for="item in betAmountOptions"
                      :key="item.amount"
                      class="coupon__bets-amount-item"
                      @click="setBetAmount(item.amount)"
                    >
                      <!--                      :class="{-->
                      <!--                      'coupon__bets-amount-item_active':-->
                      <!--                      item.amount === betAmount,-->
                      <!--                      }"-->
                      {{ item.amount }}
                    </div>
                  </div>

                  <div class="coupon__bets-win">
                    <div class="coupon__bets-win-title">
                      {{ t('COUPON.POSSIBLE_WINNINGS') }}
                    </div>
                    <div class="coupon__bets-win-result">
                      {{ possibleWinnings.toFixed(2) }}

                      <span class="coupon__bets-win-result-currency">
                        <!-- TODO correct currency -->
                        USDT
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </BasePageTabs>

            <div class="coupon__bets-submit">
              <BaseButton
                variant="secondary"
                size="full"
                class="coupon__bets-submit-btn"
                @click="clearAll"
              >
                {{ t('COUPON.CLEAR_ALL') }}
              </BaseButton>

              <BaseButton
                variant="primary"
                size="full"
                class="coupon__bets-submit-btn"
                @click="makeBet"
              >
                {{ t('COUPON.MAKE_BET') }}
              </BaseButton>

              <div class="coupon__bets-submit-share">
                <BaseIcon icon="icon-share" />
              </div>
            </div>
          </div>

          <template v-if="oneClickBet">
            <div class="coupon__bets-one-click">
              {{ t('COUPON.ONE_CLICK_DESCRIPTION') }}
            </div>

            <div class="coupon__bets-amount">
              <!-- TODO component (+settingsModal) -->
              <BaseInput
                v-model="betAmount"
                :label="t('COUPON.BET_AMOUNT')"
                with-right-text
                class="coupon__bets-amount-input"
              >
                <template #text>
                  <span class="coupon__bets-amount-text">
                    USDT
                    <!-- TODO correct currency -->
                    <!-- {{ basicUserInfo.currency }}-->
                  </span>
                </template>
              </BaseInput>

              <div
                v-for="item in betAmountOptions"
                :key="item.amount"
                class="coupon__bets-amount-item"
                @click="setBetAmount(item.amount)"
              >
                <!--                      :class="{-->
                <!--                      'coupon__bets-amount-item_active':-->
                <!--                      item.amount === betAmount,-->
                <!--                      }"-->
                {{ item.amount }}
              </div>
            </div>
          </template>

          <div v-if="isCopyExist && !oneClickBet" class="coupon__cancel">
            <BaseButton
              variant="secondary"
              size="full"
              class="coupon__cancel-btn"
              @click="cancel"
            >
              <div class="coupon__cancel-btn-container">
                <div>{{ t('COUPON.CANCEL') }}</div>
                <svg class="coupon__cancel-btn-container-icon">
                  <use href="#icon-close" />
                </svg>
              </div>
            </BaseButton>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
@import 'the-coupon';
</style>
