<script setup>
import BaseIcon from '@/components/base/base-icon/BaseIcon.vue'

import { ref, defineProps, defineEmits } from 'vue'
import { toRef } from '@vue/runtime-core'

const props = defineProps({
  show: {
    type: Boolean,
    default: true,
    required: false,
  },
  size: {
    type: String,
    default: 'sm',
    required: false,
    validator(value) {
      return ['xs', 'sm', 'md', 'lg', 'full'].includes(value)
    },
  },
  halfScreen: {
    type: Boolean,
    default: false,
    required: false,
  },
  noBottomPadding: {
    type: Boolean,
    default: false,
    required: false,
  },
  noPadding: {
    type: Boolean,
    default: false,
    required: false,
  },
  closeOnBackground: {
    type: Boolean,
    default: false,
    required: false,
  },
  hideCloseIcon: {
    type: Boolean,
    default: false,
    required: false,
  },
  closeIconInside: {
    type: Boolean,
    default: false,
    required: false,
  },
  withBorder: {
    type: Boolean,
    default: false,
    required: false,
  },
  gradientHeight: {
    type: Number,
    default: 30,
    required: false,
  },
})

const show = toRef(props, 'show')

const emit = defineEmits(['close'])

const hideOverflow = ref(false)
function enableOverflow() {
  hideOverflow.value = true
}
function disableOverflow() {
  hideOverflow.value = false
}

const contentClick = ref(false)
const modalContainer = ref(null)

function closeGallery() {
  if (props.size === 'full') close()
}

function close(hard = false) {
  if (!hard && contentClick.value === true) {
    resetContentClickValue()
    return
  }

  emit('close')
}

function onPointerdown(event, isContent) {
  contentClick.value = isContent || contentClick.value
}

function resetContentClickValue() {
  contentClick.value = false
}
</script>

<template>
  <Transition name="modals">
    <div
      v-if="show"
      ref="modalContainer"
      :class="{
        [`base-modal_size-${props.size}`]: true,
        [`base-modal_no-bottom-padding`]: props.noBottomPadding,
        [`base-modal_hide-overflow`]: hideOverflow,
        [`base-modal_half-screen`]: props.halfScreen,
        [`base-modal_with-border`]: props.withBorder,
      }"
      class="base-modal"
      @click.stop="close(false)"
      @pointerdown="onPointerdown($event, false)"
    >
      <div class="base-modal__wrapper" data-boundary="true">
        <div
          :style="{ background: `linear-gradient(180deg, #393A3D 0%, #080E13 ${props.gradientHeight}%)` }"
          class="base-modal__container"
          @click.stop="closeGallery"
        >
          <div
            v-if="!props.hideCloseIcon"
            class="base-modal__close"
            :class="{
              ['base-modal__close_inside']: props.closeIconInside,
            }"
            @click="close(true)"
          >
            <BaseIcon icon="icon-close" class="base-modal__close-icon" />
          </div>

          <div
            class="base-modal__content"
            :class="{
              [`base-modal__content_no-padding`]: props.noPadding,
            }"
            @pointerdown="onPointerdown($event, true)"
            @pointerup="resetContentClickValue"
          >
            <slot
              :enable-overflow="enableOverflow"
              :disable-overflow="disableOverflow"
            />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
@import './base-modal';
</style>
