import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useLocalStorage } from '@/store/state/localStorage'
import { useMetricSystem } from '@/composables/use-metric-system'
import useCookieSetter from '@/composables/use-cookie-setter'

export const useStateStore = defineStore('state', () => {
  const { readCookie } = useCookieSetter()

  const currency = ref('$')
  const { selectedMetric, metricsList } = useMetricSystem()

  const {
    currentList: lineCouponList,
    isAddedToStorage: isAddedLineCouponItem,
    toggleStorageItem: toggleLineCouponItem,
  } = useLocalStorage('LINE_COUPON_LIST', {
    compareCallback: (listItem, item) => {
      return (
        JSON.stringify(listItem.AddToBasket) ===
        JSON.stringify(item.AddToBasket)
      )
    },
    removeCallback: (listItem, item) => {
      return (
        JSON.stringify(listItem.AddToBasket) !==
        JSON.stringify(item.AddToBasket)
      )
    },
  })

  const {
    currentList: liveCouponList,
    isAddedToStorage: isAddedLiveCouponItem,
    toggleStorageItem: toggleLiveCouponItem,
  } = useLocalStorage('LIVE_COUPON_LIST')

  const {
    currentList: lineItemsFavouriteList,
    isAddedToStorage: isFavouriteLineItem,
    toggleStorageItem: toggleFavouriteLineItem,
  } = useLocalStorage('LINE_FAVOURITE_LIST')

  const {
    currentList: liveItemsFavouriteList,
    isAddedToStorage: isFavouriteLiveItem,
    toggleStorageItem: toggleFavouriteLiveItem,
  } = useLocalStorage('LIVE_FAVOURITE_LIST')

  const {
    currentList: gamesFavouriteList,
    isAddedToStorage: isFavouriteGame,
    toggleStorageItem: toggleFavouriteGame,
  } = useLocalStorage('GAMES_FAVOURITE_LIST')

  function setCurrency(curr) {
    currency.value = curr
  }

  function initMetric() {
    const cookieMap = readCookie()

    selectedMetric.value = cookieMap.odds || metricsList[0].code
  }

  initMetric()

  return {
    selectedMetric,

    currency,
    setCurrency,

    // line-coupon-list
    lineCouponList,
    isAddedLineCouponItem,
    toggleLineCouponItem,

    // live-coupon-list
    liveCouponList,
    isAddedLiveCouponItem,
    toggleLiveCouponItem,

    // line-favourite-list
    lineItemsFavouriteList,
    isFavouriteLineItem,
    toggleFavouriteLineItem,

    // live-favourite-list
    liveItemsFavouriteList,
    isFavouriteLiveItem,
    toggleFavouriteLiveItem,

    // games-favourite-list
    gamesFavouriteList,
    isFavouriteGame,
    toggleFavouriteGame,
  }
})
