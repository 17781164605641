import { ApiFactory } from '@/plugins/api/logic/ApiFactory'
import { GetAllAvailableContentPagesAsTree } from '@/plugins/api/logic/content/get-all-available-content-pages-as-tree'
import { GetConcreteContentPage } from '@/plugins/api/logic/content/get-concrete-content-page'

export class ContentController extends ApiFactory {
  async getAllAvailableContentPagesAsTree() {
    const request = new GetAllAvailableContentPagesAsTree(this.context)
    return await request.fetch(this.axios)
  }

  async getConcreteContentPage(data) {
    const request = new GetConcreteContentPage(this.context, data)
    return await request.fetch(this.axios)
  }
}
