import { GetApiRequest } from '@/plugins/api/base'

export class GetLineHotEvents extends GetApiRequest {
  constructor(context) {
    const culture = context.i18n.locale.value

    const url = `/api/line/${culture}/hotevents`
    super(context, url)
  }
}
