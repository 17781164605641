import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'

const CURRENT_APP_LANGUAGE = 'current-app-language'

export function useLanguageSelectorData() {
  const { locale } = useI18n()

  const LOCALES = [
    {
      Id: 2,
      Name: 'LANGUAGE.UKRAINIAN',
      Culture: 'uk-UA',
    },
    {
      Id: 1,
      Name: 'LANGUAGE.ENGLISH',
      Culture: 'en-US',
    },
    {
      Id: 3,
      Name: 'LANGUAGE.SPANISH',
      Culture: 'es-ES',
    },
    {
      Id: 4,
      Name: 'LANGUAGE.FRENCH',
      Culture: 'fr-FR',
    },
    {
      Id: 5,
      Name: 'LANGUAGE.TURKISH',
      Culture: 'tr-TR',
    },
    {
      Id: 7,
      Name: 'LANGUAGE.PORTUGUESE',
      Culture: 'pt-PT',
    },
    {
      Id: 0,
      Name: 'LANGUAGE.RUSSIAN',
      Culture: 'ru-RU',
    },
  ]

  const language = ref(null)

  const selectedLanguage = computed({
    get() {
      return language.value
    },
    set(val) {
      language.value = val
      if (val !== undefined && val !== null && val.Culture) {
        localStorage.setItem(CURRENT_APP_LANGUAGE, val.Culture)
        setTimeout(() => {
          window.location.reload()
        }, 100)
        return
      }

      localStorage.removeItem(CURRENT_APP_LANGUAGE)
    },
  })

  function setDefaultLocale() {
    language.value = LOCALES.find((item) => item.Culture === locale.value)
  }

  function getLangIcon(item) {
    const code = item.Culture
    return code ? `/imgs/flags/${code}.svg` : ''
  }

  setDefaultLocale()

  return {
    selectedLanguage,
    LOCALES,
    getLangIcon,
  }
}
