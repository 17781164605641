import { PostApiRequest } from '@/plugins/api/base'

export class IsUserExists extends PostApiRequest {
  constructor(context, data = {}) {
    const body = {
      userName: data.login,
    }

    const url = `/Bsw/BCService.asmx/IsUserExist`
    super(context, url, body)
  }
}
