import { PostApiRequest } from '@/plugins/api/base'
// TODO GET or POST?

export class GetOrderedTransactions extends PostApiRequest {
  constructor(context) {
    const body = {}

    const url = `/Bsw/BCService.asmx/GetOrderedTransactions`
    super(context, url, body)
  }
}
