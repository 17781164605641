import { useAuthStore } from '@/store/auth'
import { useApiPlugin } from '@/plugins/api'
import { useCookies } from '@vueuse/integrations/useCookies'

const Cookies = {
  accessToken: 'access_token',
  refreshToken: 'refresh_token',
}

class AuthPlugin {
  static refreshHandler = undefined

  constructor(ctx) {
    this.api = ctx?.api || useApiPlugin({ auth: this })
    this.authStore = useAuthStore()
    this.cookies = useCookies()

    if (AuthPlugin.refreshHandler === undefined) {
      AuthPlugin.refreshHandler = async () => {
        return await this.api.auth.auth.refreshTokens({
          refresh: this.refreshToken || '',
        })
      }
    }
  }

  get user() {
    return this.authStore.user
  }
  set user(value) {
    this.authStore.user = value
  }

  get loggedIn() {
    return this.authStore.loggedIn
  }

  get accessToken() {
    const sessionToken = sessionStorage.getItem(Cookies.accessToken)

    return sessionToken
      ? sessionToken
      : this.cookies.get(Cookies.accessToken) || null
  }
  set accessToken(value) {
    const sessionToken = sessionStorage.getItem(Cookies.accessToken)

    if (sessionToken) {
      this.setSessionAuthValue(Cookies.accessToken, value)
      return
    }
    this.setAuthCookie(Cookies.accessToken, value)
  }

  get refreshToken() {
    const sessionToken = sessionStorage.getItem(Cookies.refreshToken)
    return sessionToken
      ? sessionToken
      : this.cookies.get(Cookies.refreshToken) || null
  }
  set refreshToken(value) {
    const sessionToken = sessionStorage.getItem(Cookies.refreshToken)
    if (sessionToken) {
      this.setSessionAuthValue(Cookies.refreshToken, value)
      return
    }
    this.setAuthCookie(Cookies.refreshToken, value)
  }

  setAuthCookie(name, value) {
    const currentCookieValue = this.cookies.get(name)

    if (currentCookieValue === value) {
      return
    }

    const options = {
      path: '/',
      sameSite: 'lax',
    }

    if (value === null) {
      this.cookies.remove(name, options)
      return
    }

    this.cookies.set(name, value, options)
  }

  setSessionAuthValue(name, value) {
    const currentStorageValue = sessionStorage.getItem(name)
    if (currentStorageValue === value) {
      return
    }

    if (value === null) {
      sessionStorage.removeItem(name)
      return
    }

    sessionStorage.setItem(name, value)
  }

  async mount() {
    await this.fetchData()
  }

  async login(data) {
    const tokens = await this.api.auth.auth.login(data)
    this.accessToken = tokens.accessToken
    this.refreshToken = tokens.refreshToken

    const { user } = await this.fetchData()
    return user
  }

  async logout() {
    const refresh = this.refreshToken
    if (!refresh) {
      this.reset()
      throw new Error('user is not logged in')
    }

    try {
      await this.api.auth.auth.logout({ refresh }, true)
      this.reset()
    } catch {
      this.reset()
    }
  }

  reset() {
    this.accessToken = null
    this.refreshToken = null
    this.user = null
    this.setSessionAuthValue(Cookies.refreshToken, null)
    this.setSessionAuthValue(Cookies.accessToken, null)
  }

  async refreshTokens() {
    const refresh = this.refreshToken
    if (!refresh) {
      throw new Error('user is not logged in')
    }

    const tokens = await AuthPlugin.refreshHandler.request()
    this.accessToken = tokens.access
    this.refreshToken = tokens.refresh

    // await this.fetchUser(true)
  }

  setTokensForce(access, refresh) {
    if (sessionStorage.getItem(Cookies.accessToken)) {
      this.setSessionAuthValue(Cookies.accessToken, access)
      this.setSessionAuthValue(Cookies.refreshToken, refresh)
    } else {
      this.accessToken = access
      this.refreshToken = refresh
    }

    window.location.reload()
  }

  async fetchData(logoutOnFail = false) {
    if (!this.accessToken) {
      throw new Error('invalid credentials')
    }

    let user = null

    await Promise.all(
      [async () => (user = await this.fetchUser(logoutOnFail))].map((item) =>
        item()
      )
    )

    if (!this.authStore.user) {
      throw new Error('invalid user data')
    }

    return { user: this.authStore.user }
  }

  async fetchUser(logoutOnFail = false) {
    // TODO correct user request
    const profile = await this.api.logic.profile.getProfile(logoutOnFail)

    this.authStore.user = profile

    return profile
  }
}

export { AuthPlugin }
