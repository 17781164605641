import { register } from 'register-service-worker'

// Define the detectSWUpdate function at the program root
async function detectSWUpdate() {
  try {
    const registration = await navigator.serviceWorker.ready

    registration.addEventListener('updatefound', () => {
      const newSW = registration.installing
      newSW.addEventListener('statechange', () => {
        if (newSW.state === 'installed') {
          // New service worker is installed, but waiting for activation
          console.log('New service worker installed but waiting for activation')
        }
      })
    })
  } catch (error) {
    console.error('Error detecting service worker update:', error)
    throw error // Re-throw the error to propagate it further if needed
  }
}

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated() {
      console.log('New content is available; please refresh.')
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.'
      )
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })

  // Call the detectSWUpdate function after the service worker registration
  detectSWUpdate().catch((error) => {
    console.error('Error in detectSWUpdate:', error)
  })
}
