<script setup>
import BaseModal from '@/components/base/base-modal/BaseModal.vue'
import ModalHeader from './modal-header/ModalHeader.vue'
import ModalControls from './modal-controls/ModalControls.vue'

import { inject, nextTick, watch } from 'vue'
import { useExpand } from './use-expand'
import { useModalComponents } from './use-modal-components'
import { useRoute } from 'vue-router'

const route = useRoute()
const modalPlugin = inject('modalsPlugin')
const scrollLock = inject('scrollLockCustomPlugin')
const modalComponents = useModalComponents()
const { enter, afterEnter, leave } = useExpand()

watch(
  () => route.path,
  () => {
    modalPlugin.resetOpenedModals()

    if (modalPlugin.openedModalsCount === 0) {
      scrollLock.enableScroll()
    }
  }
)

const closeModal = (modal) => {
  const closeCallback = modal.options.closeCallback
  if (closeCallback) {
    closeCallback({
      close: () => modalPlugin.close(modal.id),
    })
  }

  modalPlugin.close(modal.id)
}

const onModalOpened = (el) => {
  nextTick(() => {
    if (modalPlugin.openedModalsCount === 0) {
      scrollLock.disableScroll()
    }
    modalPlugin.increaseOpenedModalsCounter()
  })
}

const onModalClosed = () => {
  modalPlugin.decreaseOpenedModalsCounter()

  if (modalPlugin.openedModalsCount === 0) {
    scrollLock.enableScroll()
  }
}
</script>

<template>
  <TransitionGroup
    name="modals"
    tag="div"
    class="modals"
    @before-enter="onModalOpened"
    @after-leave="onModalClosed"
  >
    <BaseModal
      v-for="modal in modalPlugin.openedModals"
      :key="modal.id"
      v-bind="modalComponents[modal.component].wrapperOptions"
      :modal-id="modal.id"
      class="modals__item"
      @close="closeModal(modal)"
    >
      <template #default="{ enableOverflow, disableOverflow }">
        <transition
          name="expand"
          @enter="enter($event, enableOverflow)"
          @after-enter="afterEnter($event, disableOverflow)"
          @leave="leave"
        >
          <div>
            <component
              :is="modalComponents[modal.component].component"
              v-bind="modal.options"
              :modal-id="modal.id"
              class="modals__item-content"
              @close="closeModal(modal)"
            >
              <template #header="options">
                <ModalHeader v-bind="options" @close="closeModal(modal)" />
              </template>

              <template #controls="options">
                <ModalControls v-bind="options" @close="closeModal(modal)" />
              </template>
            </component>
          </div>
        </transition>
      </template>
    </BaseModal>
  </TransitionGroup>
</template>

<style lang="scss" scoped>
@import './index';
</style>
