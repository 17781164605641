<script setup>
import { computed, defineProps } from 'vue'

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  active: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  placement: {
    type: String,
    default: 'top',
  },
  // variant: {
  //   type: String,
  //   default: 'primary',
  //   validator(value) {
  //     return ['primary', 'secondary'].includes(value)
  //   },
  // },
  // size: {
  //   type: String,
  //   default: 'md',
  //   validator(value) {
  //     return ['sm', 'md', 'lg'].includes(value)
  //   },
  // },
})

const classes = computed(() => ({
  // [`base-button_variant-${props.variant}`]: true,
  // [`base-button_size-${props.size}`]: true,
  ['tooltip_disabled']: props.disabled,
  [`tooltip_active`]: props.active,
  ['tooltip_loading']: props.loading,
}))
</script>

<template>
  <div class="tooltip" :class="classes">
    <Popper arrow :placement="props.placement" class="tooltip__component">
      <slot />

      <template #content>
        <div class="tooltip__content">
          <slot name="content" />
        </div>
      </template>
    </Popper>
  </div>
</template>

<style lang="scss" scoped>
@import 'base-tooltip';
</style>
