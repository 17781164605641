export default {
  GENERAL: {
    HOME: 'Дім',
    LIVE: 'Прямий ефір',
    PRE_MATCH: 'Передматчеві',
    GAMES: 'Ігри',
    RESULTS: 'Результати',
    SUPPORT: 'Підтримка',
    INFO: 'Інформація',
    BETS: 'Ставки',
    DEPOSIT: 'Депозит',

    SAVE: 'Зберегти',

    18: '18+',
    GO: 'Вперед!',
    BONUSES_PROMO: 'Бонуси та акції 2024',
    SUPPORT_24: 'Підтримка 24\\7',
    SELECT_LANGUAGES: 'Оберіть мову',
  },

  LANGUAGE: {
    UKRAINIAN: 'Українська',
    ENGLISH: 'Англійська',
    SPANISH: 'Іспанська',
    FRENCH: 'Французька',
    TURKISH: 'Турецька',
    PORTUGUESE: 'Португальська',
    RUSSIAN: 'Російська',
  },

  AUTH: {
    LOGIN: 'Login',
    LOGOUT: 'Logout',
    REGISTRATION: 'Registration',
    EMAIL: 'Email',
    ENTER_EMAIL: 'Enter email',
    PASSWORD: 'Password',
    ENTER_PASSWORD: 'Enter password',
    NUMBER_EMAIL: 'Phone number or email',
    LOGIN_TITLE: 'Welcome',
    FORGOT: 'Forgot your password?',
    WARNING_TEXT:
      'After registration, you cannot change currency in your profile',
    CONFIRM_TEXT:
      'I hereby confirm that I am over 18 years of age and have read and agree to all applicance',
    RULES_LINK: 'rules and regulations',
    RECOVERY: 'Access recovery',
    NEW_PASSWORD: 'New Password',
    RESTORE: 'Restore',
    DID_NOT_CODE: 'Didn`t receive the code?',
    TIMER_TEXT: 'You can request a new code in',
    SECONDS: 'seconds',
    REQUEST_CODE: 'Request a new code',
    CREATE_NEW_PASSWORD: 'Create new password',
    REPEAT_PASSWORD: 'Repeat password',
    VERIFICATION_CODE: 'Verification code',
  },

  HOT_EVENTS: {
    TITLE: 'Гарячі події',
    SEE_ALL: 'Переглянути все',
  },
}
