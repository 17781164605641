<script setup>
import BaseButton from '@/components/base/base-button/BaseButton.vue'

import { ref, defineProps, defineEmits } from 'vue'

const props = defineProps({
  primaryButtonCallback: {
    type: Function,
    default: null,
    required: false,
  },
  primaryButtonText: {
    type: String,
    default: '',
    required: false,
  },
  secondaryButtonCallback: {
    type: Function,
    default: null,
    required: false,
  },
  secondaryButtonText: {
    type: String,
    default: '',
    required: false,
  },
  primaryDisabled: {
    type: Boolean,
    default: false,
    required: false,
  },
  secondaryDisabled: {
    type: Boolean,
    default: false,
    required: false,
  },
  disabled: {
    type: Boolean,
    default: false,
    required: false,
  },
})
const emit = defineEmits(['close'])

const isLoading = ref(false)

async function callPrimaryAction() {
  isLoading.value = true

  const callback = props.primaryButtonCallback
  if (callback) {
    await callback({ close })
  }

  isLoading.value = false
}

async function callSecondaryAction() {
  isLoading.value = true

  const callback = props.secondaryButtonCallback
  if (callback) {
    await callback({ close })
  } else {
    close()
  }

  isLoading.value = false
}

function close() {
  emit('close')
}
</script>

<template>
  <div
    v-if="props.primaryButtonCallback || props.secondaryButtonCallback"
    class="modal-controls"
  >
    <BaseButton
      v-if="props.secondaryButtonCallback"
      :disabled="props.secondaryDisabled || props.disabled || isLoading"
      class="modal-controls__item"
      variant="secondary"
      size="full"
      @click="callSecondaryAction"
    >
      <!--      TODO TRANSLATE-->
      {{ secondaryButtonText || 'Back' }}
    </BaseButton>

    <BaseButton
      v-if="props.primaryButtonCallback"
      :disabled="props.primaryDisabled || props.disabled || isLoading"
      class="modal-controls__item"
      variant="primary"
      size="full"
      @click="callPrimaryAction"
    >
      <!--      TODO TRANSLATE-->
      {{ primaryButtonText || 'CONTINUE' }}
    </BaseButton>
  </div>
  <div></div>
</template>

<style lang="scss" scoped>
@import './modal-controls';
</style>
