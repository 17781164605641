import { GetApiRequest } from '@/plugins/api/base'

export class GetAllAvailableContentPagesAsTree extends GetApiRequest {
  constructor(context) {
    const params = { culture: context.i18n.locale.value }

    const url = 'api/content/getAllAvailableContentPagesAsTree'

    super(context, url, params)
  }
}
