import { ref } from 'vue'

export function useMetricSystem() {
  const selectedMetric = ref(null)

  const metricsList = [
    {
      name: 'COUPON.DECIMAL',
      code: 'decimal',
    },
    {
      name: 'COUPON.FRACTIONAL',
      code: 'fractional',
    },
    {
      name: 'COUPON.AMERICAN',
      code: 'american',
    },
    {
      name: 'COUPON.HONG_KONG',
      code: 'hongkong',
    },
    {
      name: 'COUPON.INDONESIAN',
      code: 'indo',
    },
    {
      name: 'COUPON.MALAYSIAN',
      code: 'malay',
    },
  ]

  const oddConvert = {
    decimal: (val) => val,
    hongkong: (val) => {
      const t = Number((val - 1).toFixed(4))
      return t.toFixed(2)
    },
    indo: (val) => {
      const t = Number((val - 1).toFixed(4))
      const v = val < 2 ? -1 / t : t
      return v.toFixed(2)
    },
    malay: (val) => {
      const t = Number((val - 1).toFixed(4))
      const v = val < 2 ? t : -1 / t
      return v.toFixed(2)
    },
    american: (val) => {
      const t = Number((val - 1).toFixed(4))
      const v = val < 2 ? -100 / t : 100 * t
      return v.toFixed(0)
    },
    fractional: (valMain) => {
      try {
        const t = Number((valMain - 1).toFixed(4))
        const base = Math.pow(10, degree(t, 0))
        return base == 1 ? t + '/' + base : rest(t * base, base, 2)
      } catch (error) {
        return fractional2(valMain)
      }
    },
  }

  function degree(val, i) {
    if ((val * Math.pow(10, i)) % 1 != 0) {
      return degree(val, i + 1)
    } else {
      return i
    }
  }

  function rest(val, base, i) {
    if (base % i != 0) {
      return rest(val, base, i + 1)
    } else {
      var r = parseInt(base / i)
      if (val % r != 0) {
        return rest(val, base, i + 1)
      } else {
        return parseInt(val / r) + '/' + i
      }
    }
  }

  function hcfof(num1, num2) {
    let firnum, secnum
    if (num1 < num2) {
      firnum = num1
      secnum = num2
    } else {
      firnum = num2
      secnum = num1
    }

    let rem = secnum % firnum

    while (rem > 0) {
      secnum = firnum
      firnum = rem
      rem = secnum % firnum
    }

    const hcf = firnum
    return hcf
  }

  function fractional2(aa) {
    aa = aa - 1
    aa = aa * 1
    const bb = Math.floor(aa)
    const cc = aa - bb

    let dd = 1
    if (cc != 0) dd = Math.pow(10, aa.toString().length - 2)
    let ee = Math.round(aa * dd)

    const hcf = hcfof(ee, dd)
    ee = ee / hcf
    dd = dd / hcf

    return `${ee}/${dd}`
  }

  function formatNumberByMetric(metric, val) {
    const cb = oddConvert[metric]
    return cb ? cb(val) : val
  }

  return {
    selectedMetric,
    metricsList,
    formatNumberByMetric,
  }
}
