import axios from 'axios'
import { BaseApiFactory } from '@/plugins/api/base'

export class ApiFactory extends BaseApiFactory {
  constructor(context) {
    const axiosInstance = axios.create({
      baseURL: process.env.API_BASE_URL,
    })

    super(context, axiosInstance)
  }
}
