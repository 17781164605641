export const systemOptions = [
  { name: '2/3', code: 3, eventNumber: 3, needNumber: 2, possibleNumber: 3 },
  { name: '2/4', code: 4, eventNumber: 4, needNumber: 2, possibleNumber: 6 },
  { name: '2/5', code: 5, eventNumber: 5, needNumber: 2, possibleNumber: 10 },
  { name: '2/6', code: 6, eventNumber: 6, needNumber: 2, possibleNumber: 15 },
  { name: '2/7', code: 7, eventNumber: 7, needNumber: 2, possibleNumber: 21 },
  { name: '2/8', code: 8, eventNumber: 8, needNumber: 2, possibleNumber: 28 },
  { name: '2/9', code: 9, eventNumber: 9, needNumber: 2, possibleNumber: 36 },
  {
    name: '2/10',
    code: 10,
    eventNumber: 10,
    needNumber: 2,
    possibleNumber: 45,
  },
  {
    name: '2/11',
    code: 11,
    eventNumber: 11,
    needNumber: 2,
    possibleNumber: 55,
  },
  {
    name: '2/12',
    code: 12,
    eventNumber: 12,
    needNumber: 2,
    possibleNumber: 66,
  },
  {
    name: '2/13',
    code: 13,
    eventNumber: 13,
    needNumber: 2,
    possibleNumber: 78,
  },
  {
    name: '2/14',
    code: 14,
    eventNumber: 14,
    needNumber: 2,
    possibleNumber: 91,
  },
  {
    name: '2/15',
    code: 15,
    eventNumber: 15,
    needNumber: 2,
    possibleNumber: 105,
  },
  {
    name: '2/16',
    code: 16,
    eventNumber: 16,
    needNumber: 2,
    possibleNumber: 120,
  },
  { name: '3/4', code: 4, eventNumber: 4, needNumber: 3, possibleNumber: 4 },
  { name: '3/5', code: 5, eventNumber: 5, needNumber: 3, possibleNumber: 10 },
  { name: '3/6', code: 6, eventNumber: 6, needNumber: 3, possibleNumber: 20 },
  { name: '3/7', code: 7, eventNumber: 7, needNumber: 3, possibleNumber: 35 },
  { name: '3/8', code: 8, eventNumber: 8, needNumber: 3, possibleNumber: 56 },
  { name: '3/9', code: 9, eventNumber: 9, needNumber: 3, possibleNumber: 84 },
  {
    name: '3/10',
    code: 10,
    eventNumber: 10,
    needNumber: 3,
    possibleNumber: 120,
  },
  {
    name: '3/11',
    code: 11,
    eventNumber: 11,
    needNumber: 3,
    possibleNumber: 165,
  },
  {
    name: '3/12',
    code: 12,
    eventNumber: 12,
    needNumber: 3,
    possibleNumber: 220,
  },
  {
    name: '3/13',
    code: 13,
    eventNumber: 13,
    needNumber: 3,
    possibleNumber: 286,
  },
  {
    name: '3/14',
    code: 14,
    eventNumber: 14,
    needNumber: 3,
    possibleNumber: 364,
  },
  {
    name: '3/15',
    code: 15,
    eventNumber: 15,
    needNumber: 3,
    possibleNumber: 455,
  },
  {
    name: '3/16',
    code: 16,
    eventNumber: 16,
    needNumber: 3,
    possibleNumber: 560,
  },
  { name: '4/5', code: 5, eventNumber: 5, needNumber: 4, possibleNumber: 5 },
  { name: '4/6', code: 6, eventNumber: 6, needNumber: 4, possibleNumber: 15 },
  { name: '4/7', code: 7, eventNumber: 7, needNumber: 4, possibleNumber: 35 },
  { name: '4/8', code: 8, eventNumber: 8, needNumber: 4, possibleNumber: 70 },
  { name: '4/9', code: 9, eventNumber: 9, needNumber: 4, possibleNumber: 126 },
  {
    name: '4/10',
    code: 10,
    eventNumber: 10,
    needNumber: 4,
    possibleNumber: 210,
  },
  {
    name: '4/11',
    code: 11,
    eventNumber: 11,
    needNumber: 4,
    possibleNumber: 330,
  },
  {
    name: '4/12',
    code: 12,
    eventNumber: 12,
    needNumber: 4,
    possibleNumber: 495,
  },
  {
    name: '4/13',
    code: 13,
    eventNumber: 13,
    needNumber: 4,
    possibleNumber: 715,
  },
  {
    name: '4/14',
    code: 14,
    eventNumber: 14,
    needNumber: 4,
    possibleNumber: 1001,
  },
  {
    name: '4/15',
    code: 15,
    eventNumber: 15,
    needNumber: 4,
    possibleNumber: 1365,
  },
  {
    name: '4/16',
    code: 16,
    eventNumber: 16,
    needNumber: 4,
    possibleNumber: 1820,
  },
  { name: '5/6', code: 6, eventNumber: 6, needNumber: 5, possibleNumber: 6 },
  { name: '5/7', code: 7, eventNumber: 7, needNumber: 5, possibleNumber: 21 },
  { name: '5/8', code: 8, eventNumber: 8, needNumber: 5, possibleNumber: 56 },
  { name: '5/9', code: 9, eventNumber: 9, needNumber: 5, possibleNumber: 126 },
  {
    name: '5/10',
    code: 10,
    eventNumber: 10,
    needNumber: 5,
    possibleNumber: 252,
  },
  {
    name: '5/11',
    code: 11,
    eventNumber: 11,
    needNumber: 5,
    possibleNumber: 462,
  },
  {
    name: '5/12',
    code: 12,
    eventNumber: 12,
    needNumber: 5,
    possibleNumber: 792,
  },
  {
    name: '5/13',
    code: 13,
    eventNumber: 13,
    needNumber: 5,
    possibleNumber: 1287,
  },
  {
    name: '5/14',
    code: 14,
    eventNumber: 14,
    needNumber: 5,
    possibleNumber: 2002,
  },
  {
    name: '5/15',
    code: 15,
    eventNumber: 15,
    needNumber: 5,
    possibleNumber: 3003,
  },
  {
    name: '5/16',
    code: 16,
    eventNumber: 16,
    needNumber: 5,
    possibleNumber: 4368,
  },
  { name: '6/7', code: 7, eventNumber: 7, needNumber: 6, possibleNumber: 7 },
  { name: '6/8', code: 8, eventNumber: 8, needNumber: 6, possibleNumber: 28 },
  { name: '6/9', code: 9, eventNumber: 9, needNumber: 6, possibleNumber: 84 },
  {
    name: '6/10',
    code: 10,
    eventNumber: 10,
    needNumber: 6,
    possibleNumber: 210,
  },
  {
    name: '6/11',
    code: 11,
    eventNumber: 11,
    needNumber: 6,
    possibleNumber: 462,
  },
  {
    name: '6/12',
    code: 12,
    eventNumber: 12,
    needNumber: 6,
    possibleNumber: 924,
  },
  {
    name: '6/13',
    code: 13,
    eventNumber: 13,
    needNumber: 6,
    possibleNumber: 1716,
  },
  {
    name: '6/14',
    code: 14,
    eventNumber: 14,
    needNumber: 6,
    possibleNumber: 3003,
  },
  {
    name: '6/15',
    code: 15,
    eventNumber: 15,
    needNumber: 6,
    possibleNumber: 5005,
  },
  {
    name: '6/16',
    code: 16,
    eventNumber: 16,
    needNumber: 6,
    possibleNumber: 8008,
  },
  { name: '7/8', code: 8, eventNumber: 8, needNumber: 7, possibleNumber: 8 },
  { name: '7/9', code: 9, eventNumber: 9, needNumber: 7, possibleNumber: 36 },
  {
    name: '7/10',
    code: 10,
    eventNumber: 10,
    needNumber: 7,
    possibleNumber: 120,
  },
  {
    name: '7/11',
    code: 11,
    eventNumber: 11,
    needNumber: 7,
    possibleNumber: 330,
  },
  {
    name: '7/12',
    code: 12,
    eventNumber: 12,
    needNumber: 7,
    possibleNumber: 792,
  },
  {
    name: '7/13',
    code: 13,
    eventNumber: 13,
    needNumber: 7,
    possibleNumber: 1716,
  },
  {
    name: '7/14',
    code: 14,
    eventNumber: 14,
    needNumber: 7,
    possibleNumber: 3432,
  },
  {
    name: '7/15',
    code: 15,
    eventNumber: 15,
    needNumber: 7,
    possibleNumber: 6435,
  },
  {
    name: '7/16',
    code: 16,
    eventNumber: 16,
    needNumber: 7,
    possibleNumber: 11440,
  },
  { name: '8/9', code: 9, eventNumber: 9, needNumber: 8, possibleNumber: 9 },
  {
    name: '8/10',
    code: 10,
    eventNumber: 10,
    needNumber: 8,
    possibleNumber: 45,
  },
  {
    name: '8/11',
    code: 11,
    eventNumber: 11,
    needNumber: 8,
    possibleNumber: 165,
  },
  {
    name: '8/12',
    code: 12,
    eventNumber: 12,
    needNumber: 8,
    possibleNumber: 495,
  },
  {
    name: '8/13',
    code: 13,
    eventNumber: 13,
    needNumber: 8,
    possibleNumber: 1287,
  },
  {
    name: '8/14',
    code: 14,
    eventNumber: 14,
    needNumber: 8,
    possibleNumber: 3003,
  },
  {
    name: '8/15',
    code: 15,
    eventNumber: 15,
    needNumber: 8,
    possibleNumber: 6435,
  },
  {
    name: '8/16',
    code: 16,
    eventNumber: 16,
    needNumber: 8,
    possibleNumber: 12870,
  },
  {
    name: '9/10',
    code: 10,
    eventNumber: 10,
    needNumber: 9,
    possibleNumber: 10,
  },
  {
    name: '9/11',
    code: 11,
    eventNumber: 11,
    needNumber: 9,
    possibleNumber: 55,
  },
  {
    name: '9/12',
    code: 12,
    eventNumber: 12,
    needNumber: 9,
    possibleNumber: 220,
  },
  {
    name: '9/13',
    code: 13,
    eventNumber: 13,
    needNumber: 9,
    possibleNumber: 715,
  },
  {
    name: '9/14',
    code: 14,
    eventNumber: 14,
    needNumber: 9,
    possibleNumber: 2002,
  },
  {
    name: '9/15',
    code: 15,
    eventNumber: 15,
    needNumber: 9,
    possibleNumber: 5005,
  },
  {
    name: '9/16',
    code: 16,
    eventNumber: 16,
    needNumber: 9,
    possibleNumber: 11440,
  },
  {
    name: '10/11',
    code: 11,
    eventNumber: 11,
    needNumber: 10,
    possibleNumber: 11,
  },
  {
    name: '10/12',
    code: 12,
    eventNumber: 12,
    needNumber: 10,
    possibleNumber: 66,
  },
  {
    name: '10/13',
    code: 13,
    eventNumber: 13,
    needNumber: 10,
    possibleNumber: 286,
  },
  {
    name: '10/14',
    code: 14,
    eventNumber: 14,
    needNumber: 10,
    possibleNumber: 1001,
  },
  {
    name: '10/15',
    code: 15,
    eventNumber: 15,
    needNumber: 10,
    possibleNumber: 3003,
  },
  {
    name: '10/16',
    code: 16,
    eventNumber: 16,
    needNumber: 10,
    possibleNumber: 8008,
  },
  {
    name: '11/12',
    code: 12,
    eventNumber: 12,
    needNumber: 11,
    possibleNumber: 12,
  },
  {
    name: '11/13',
    code: 13,
    eventNumber: 13,
    needNumber: 11,
    possibleNumber: 78,
  },
  {
    name: '11/14',
    code: 14,
    eventNumber: 14,
    needNumber: 11,
    possibleNumber: 364,
  },
  {
    name: '11/15',
    code: 15,
    eventNumber: 15,
    needNumber: 11,
    possibleNumber: 1365,
  },
  {
    name: '11/16',
    code: 16,
    eventNumber: 16,
    needNumber: 11,
    possibleNumber: 4368,
  },
  {
    name: '12/13',
    code: 13,
    eventNumber: 13,
    needNumber: 12,
    possibleNumber: 13,
  },
  {
    name: '12/14',
    code: 14,
    eventNumber: 14,
    needNumber: 12,
    possibleNumber: 91,
  },
  {
    name: '12/15',
    code: 15,
    eventNumber: 15,
    needNumber: 12,
    possibleNumber: 455,
  },
  {
    name: '12/16',
    code: 16,
    eventNumber: 16,
    needNumber: 12,
    possibleNumber: 1820,
  },
  {
    name: '13/14',
    code: 14,
    eventNumber: 14,
    needNumber: 13,
    possibleNumber: 14,
  },
  {
    name: '13/15',
    code: 15,
    eventNumber: 15,
    needNumber: 13,
    possibleNumber: 105,
  },
  {
    name: '13/16',
    code: 16,
    eventNumber: 16,
    needNumber: 13,
    possibleNumber: 560,
  },
  {
    name: '14/15',
    code: 15,
    eventNumber: 15,
    needNumber: 14,
    possibleNumber: 15,
  },
  {
    name: '14/16',
    code: 16,
    eventNumber: 16,
    needNumber: 14,
    possibleNumber: 120,
  },
  {
    name: '15/16',
    code: 16,
    eventNumber: 16,
    needNumber: 15,
    possibleNumber: 16,
  },
]
