<script setup>
import BaseLogo from '@/components/base/base-logo/BaseLogo.vue'
import BaseButton from '@/components/base/base-button/BaseButton.vue'
import MobileMenu from '@/components/general/mobile-menu/MobileMenu.vue'
import BaseIcon from '@/components/base/base-icon/BaseIcon.vue'

import { inject, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useApiPlugin } from '@/plugins/api'

const { t } = useI18n()
const router = useRouter()
const api = useApiPlugin()

const showMobileMenu = ref(false)
const isLogged = ref(false)
const massagesCount = ref(22)
const scrollLockCustomPlugin = inject('scrollLockCustomPlugin')

const user = {
  id: 1234567,
  account: '6M',
  currency: 'USD',
}

onMounted(init)

async function init() {
  try {
    const res = await api.logic.profile.getUserBalance()
    console.log(res)
  } catch (e) {
    console.error(e)
  }
}

// async function logout() {
//   await this.$auth.logout()
// },

function toggleMobileMenu(type) {
  if (type === 'close') {
    showMobileMenu.value = false
  } else {
    showMobileMenu.value = !showMobileMenu.value
  }

  showMobileMenu.value
    ? scrollLockCustomPlugin.disableScroll()
    : scrollLockCustomPlugin.enableScroll()
}

function goTo(link) {
  router.push(link)
}
</script>

<template>
  <header class="header">
    <div class="header__content" :class="{ header__content_logged: isLogged }">
      <div class="header__content-left">
        <div class="header__menu-trigger" @click="toggleMobileMenu">
          <BaseIcon icon="icon-menu" class="header__menu-trigger-icon" />
        </div>

        <div class="header__logo">
          <BaseLogo />
        </div>
      </div>

      <div class="header__content-right">
        <template v-if="isLogged">
          <div class="header__user-data" @click="goTo('/profile')">
            <div class="header__user-data-icon">
              <BaseIcon icon="icon-user" />
            </div>
            <div class="header__user-data-id">ID {{ user.id }}</div>
            <div class="header__user-data-balance">
              {{ user.account }} {{ user.currency }}
            </div>
          </div>

          <div class="header__controls">
            <div class="header__controls-massage-wrapper">
              <BaseButton
                tag="RouterLink"
                to="/messages"
                variant="black"
                size="sm"
                no-padding
                class="header__controls-massage-btn"
              >
                <div class="header__controls-massage">
                  <svg class="header__controls-massage-icon">
                    <use href="#icon-massage" />
                  </svg>
                </div>
              </BaseButton>
              <div class="header__controls-massage-badge messages-badge">
                {{ massagesCount }}
              </div>
            </div>

            <BaseButton
              tag="RouterLink"
              to="/deposit"
              size="sm"
              no-padding
              class="header__controls-deposit-btn"
            >
              <div class="header__controls-deposit">
                <svg class="header__controls-deposit-icon">
                  <use href="#icon-plus" />
                </svg>
              </div>
            </BaseButton>
          </div>
        </template>

        <div v-else class="header__auth">
          <RouterLink to="/login" class="header__auth-login">
            {{ t('AUTH.LOGIN') }}
          </RouterLink>
          <BaseButton
            tag="RouterLink"
            to="/registration"
            size="sm"
            class="header__auth-registration"
          >
            <span class="header__auth-registration-text">
              {{ t('AUTH.REGISTRATION') }}
            </span>
          </BaseButton>
        </div>
      </div>
    </div>
  </header>

  <MobileMenu
    :is-logged="isLogged"
    :show-menu="showMobileMenu"
    @toggleMenu="toggleMobileMenu"
  />

  <transition name="fade">
    <div
      v-if="showMobileMenu"
      class="header__blur"
      @click="toggleMobileMenu('close')"
    ></div>
  </transition>
</template>

<style lang="scss" scoped>
@import 'the-header';
</style>
