import { AuthService } from '@/plugins/api/auth'
import { LogicService } from '@/plugins/api/logic'
import { useAuthPlugin } from '@/plugins/auth'
import { useI18n } from 'vue-i18n'
import { toast } from 'vue3-toastify'

export class ApiPlugin {
  constructor(ctx) {
    const fullContext = {
      auth: ctx?.auth || useAuthPlugin({ api: this }),
      i18n: ctx?.i18n || useI18n(),
      toast: ctx?.toast || toast,
    }

    this.auth = new AuthService(fullContext)
    this.logic = new LogicService(fullContext)
  }
}

let instance = null

export default {
  install: () => {},
}

export function useApiPlugin(ctx) {
  if (!instance) {
    instance = new ApiPlugin(ctx)
  }

  return instance
}
