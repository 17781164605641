import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useAuthStore = defineStore('auth', () => {
  const user = ref(null)

  const loggedIn = computed(() => !!user.value)

  return {
    loggedIn,
    user,
  }
})
