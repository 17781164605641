import { PostApiRequest } from '@/plugins/api/base'

export class StartPhoneVerification extends PostApiRequest {
  constructor(context, data = {}) {
    const body = {
      PhoneNumber: data.phoneNumber,
    }

    const url = '/api/account/startPhoneVerification'
    super(context, url, body)
  }
}
