export default function useCookieSetter() {
  const setCookie = (cookieName, cookieValue, cookieOptions = null) => {
    const newDate = new Date()
    newDate.setDate(newDate.getDate() + 7) // 7 days
    // expiresDate.setFullYear(expiresDate.getFullYear() + 1); // 1 year

    const expiresDate = newDate.toUTCString()

    // const path =

    document.cookie = `${cookieName}=${cookieValue}; expires=${expiresDate};` // path=/;
  }

  const readCookie = () => {
    const cookies = document.cookie
      .split(';')
      .map((cookie) => cookie.trim().split('='))
    const cookieMap = Object.fromEntries(cookies)

    return cookieMap
  }

  return {
    setCookie,
    readCookie,
  }
}
