<script setup>
import { computed, defineEmits, defineProps } from '@vue/runtime-core'

const props = defineProps({
  value: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
    required: false,
  },
  readonly: {
    type: Boolean,
    default: false,
    required: false,
  },
  title: {
    type: String,
    default: '',
    required: false,
  },
  rowReverse: {
    type: Boolean,
    default: false,
    required: false,
  },
  useShadow: {
    type: Boolean,
    default: false,
    required: false,
  },
})

const emit = defineEmits(['update:value', 'change'])
const onChange = () => {
  emit('update:value', !model.value)
  emit('change')
}

const model = computed({
  get() {
    return props.value
  },
  set(value) {
    emit('update:value', value)
  },
})
</script>

<template>
  <div
    v-bind="$attrs"
    tabindex="0"
    role="checkbox"
    :aria-checked="props.value"
    class="switcher"
    :class="{
      disabled,
      checked: model,
      reverse: props.rowReverse,
      readonly: props.readonly,
      switcher_shadow: props.useShadow,
    }"
    @click="onChange"
  >
    <span class="switcher-track">
      <span class="switcher-track__indicator"></span>
    </span>

    <label v-if="title" class="switcher__label">{{ title }}</label>
  </div>
</template>

<style lang="scss" scoped>
@import 'base-switcher';
</style>
