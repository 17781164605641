import { PostApiRequest } from '@/plugins/api/base'

export class Registration extends PostApiRequest {
  constructor(context, data = {}) {
    const culture = context.i18n.locale.value

    const body = {
      EmailOrPhone: data.emailOrPhone,
      Password: data.password,
      PasswordRepeat: data.passwordRepeat,
      CurrencyId: data.currencyId,
      Culture: culture,
    }

    const url = `/api/account/${culture}/registration`
    super(context, url, body)
  }
}
