<script setup>
import BaseIcon from '@/components/base/base-icon/BaseIcon.vue'
import SimpleCardContainer from '@/components/pages/simple-card-container/SimpleCardContainer.vue'

import { computed, defineProps, defineEmits } from 'vue'

const props = defineProps({
  info: {
    type: Object,
    default: () => {},
    required: true,
  },
})

const emit = defineEmits(['delete-odd'])

const sportIcon = computed(() => {
  return props.info?.SportName
    ? `/imgs/sports/${props.info?.SportName.toLowerCase()}.png`
    : ''
})

function deleteOdd() {
  emit('delete-odd')
}
</script>

<template>
  <SimpleCardContainer with-shadow class="info">
    <img
      :src="sportIcon"
      class="info__sport-icon"
      alt="sports"
      @click="deleteOdd"
    />

    <div class="info__container">
      <div
        :class="{ 'info__main_without-id': !props.info?.Id }"
        class="info__main"
      >
        <div class="info__head">
          <div class="info__head-id">
            {{ props.info?.Id }}
          </div>

          <BaseIcon
            icon="icon-close"
            class="info__delete-icon"
            @click="deleteOdd"
          />
        </div>

        <RouterLink to="/event-line">
          <div class="info__main-teams">
            <span class="info__main-team">
              {{ props.info?.HomeTeamName }}
            </span>
            -
            <span class="info__main-team">
              {{ props.info?.AwayTeamName }}
            </span>
          </div>
          <div class="info__main-rate-name">
            <span>
              {{ props.info?.NameLong }}
            </span>
          </div>
        </RouterLink>

        <div class="info__main-rate">
          {{ props.info?.Rate }}
        </div>
      </div>
    </div>
  </SimpleCardContainer>
</template>

<style scoped lang="scss">
@import 'bet-info-block';
</style>
