export default {
  GENERAL: {
    SAVE: 'Сохранить',
    SELECT_LANGUAGES: 'Выберите язык',
  },

  LANGUAGE: {
    UKRAINIAN: 'Украинский',
    ENGLISH: 'Английский',
    SPANISH: 'Испанский',
    FRENCH: 'Французкий',
    TURKISH: 'Турецкий',
    PORTUGUESE: 'Португальсикй',
    RUSSIAN: 'Русский',
  },

  AUTH: {
    LOGIN: 'Войти',
    REGISTRATION: 'Регистрация',
  },
}
