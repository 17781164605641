import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useModalStore = defineStore('modal', () => {
  const openedModals = ref([])
  const idCounter = ref(0)
  const openedModalsCount = ref(0)

  function openModal({ component, options, wrapperOptions }) {
    const modal = {
      id: idCounter.value++,
      component,
      options: options || {},
      wrapperOptions: wrapperOptions || {},
    }

    openedModals.value.push(modal)
  }

  function closeModal(id) {
    const index = openedModals.value.findIndex((item) => item.id === id)
    if (index === -1) {
      return
    }

    openedModals.value.splice(index, 1)
  }

  function increaseOpenedModalCounter() {
    openedModalsCount.value++
  }

  function decreaseOpenedModalCounter() {
    if (openedModalsCount.value <= 0) {
      openedModalsCount.value = 0
      return
    }
    openedModalsCount.value--
  }

  function resetOpenedModals() {
    openedModals.value = []
    openedModalsCount.value = 0
  }

  return {
    openedModals: computed(() => openedModals.value),
    openedModalsCount: computed(() => openedModalsCount.value),
    increaseOpenedModalCounter,
    decreaseOpenedModalCounter,
    openModal,
    closeModal,
    resetOpenedModals,
  }
})
