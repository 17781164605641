import { ApiFactory } from '@/plugins/api/auth/ApiFactory'
import { Login } from '@/plugins/api/auth/auth/login'
import { Registration } from '@/plugins/api/auth/auth/registration'
import { IsUserExists } from '@/plugins/api/auth/auth/is-user-exists'
import { StartPhoneVerification } from '@/plugins/api/auth/auth/start-phone-verification'
import { SendRestorePasswordEmail } from '@/plugins/api/auth/auth/send-restore-password-email'
import { RestorePasswordByPhone } from '@/plugins/api/auth/auth/restore-password-by-phone'

export class AuthController extends ApiFactory {
  async login(data) {
    const request = new Login(this.context, data)
    return await request.fetch(this.axios)
  }
  async registration(data) {
    const request = new Registration(this.context, data)
    return await request.fetch(this.axios)
  }

  async isUserExists(data) {
    const request = new IsUserExists(this.context, data)
    return await request.fetch(this.axios)
  }

  async startPhoneVerification(data) {
    const request = new StartPhoneVerification(this.context, data)
    return await request.fetch(this.axios)
  }

  async sendRestorePasswordEmail(data) {
    const request = new SendRestorePasswordEmail(this.context, data)
    return await request.fetch(this.axios)
  }

  async restorePasswordByPhone(data) {
    const request = new RestorePasswordByPhone(this.context, data)
    return await request.fetch(this.axios)
  }
}
