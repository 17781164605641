import { useModalStore } from '@/store/modal'

export class ModalPlugin {
  constructor() {
    this.modalStore = useModalStore()
  }

  get openedModals() {
    return this.modalStore.openedModals
  }

  get openedModalsCount() {
    return this.modalStore.openedModalsCount
  }

  open(item) {
    this.modalStore.openModal(item)
  }

  close(id) {
    this.modalStore.closeModal(id)
  }

  increaseOpenedModalsCounter() {
    this.modalStore.increaseOpenedModalCounter()
  }

  decreaseOpenedModalsCounter() {
    this.modalStore.decreaseOpenedModalCounter()
  }

  resetOpenedModals() {
    this.modalStore.resetOpenedModals()
  }
}
