import { HandbookController } from '@/plugins/api/logic/handbook'
import { LineEventsController } from '@/plugins/api/logic/line'
import { ContentController } from '@/plugins/api/logic/content'
import { ProfileController } from '@/plugins/api/logic/profile'
import { ResultsController } from '@/plugins/api/logic/results'

export class LogicService {
  constructor(ctx) {
    this.handbook = new HandbookController(ctx)
    this.line = new LineEventsController(ctx)
    this.content = new ContentController(ctx)
    this.profile = new ProfileController(ctx)
    this.results = new ResultsController(ctx)
  }
}
