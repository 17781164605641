import { ApiFactory } from '@/plugins/api/logic/ApiFactory'
import { GetSports } from '@/plugins/api/logic/results/get-sports'
import { GetTournaments } from '@/plugins/api/logic/results/get-tournaments'
import { GetResults } from '@/plugins/api/logic/results/get-results'
import { GetAdditionalResult } from '@/plugins/api/logic/results/get-additional-result'

export class ResultsController extends ApiFactory {
  async getSports(data) {
    const request = new GetSports(this.context, data)
    return await request.fetch(this.axios)
  }

  async getTournaments(data) {
    const request = new GetTournaments(this.context, data)
    return await request.fetch(this.axios)
  }

  async getResults(data) {
    const request = new GetResults(this.context, data)
    return await request.fetch(this.axios)
  }

  async getAdditionalResult(data) {
    const request = new GetAdditionalResult(this.context, data)
    return await request.fetch(this.axios)
  }
}
