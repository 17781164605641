import { GetApiRequest } from '@/plugins/api/base'

export class GetResults extends GetApiRequest {
  constructor(context, data = {}) {
    const culture = context.i18n.locale.value

    const url = `api/results/${culture}/sport${data.sportId}/country${data.countryId}/tournament${data.tournamentId}/${data.periodType}/${data.start}/${data.end}`
    super(context, url)
  }
}
