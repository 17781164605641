<script setup>
import TheCoupon from '@/components/general/the-coupon/TheCoupon.vue'

import { inject, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()
const { t } = useI18n()

// TODO correct links
const footerItems = ref([
  {
    icon: 'pre-match',
    name: 'GENERAL.PRE_MATCH',
    link: '/sport/pre-match',
  },
  {
    icon: 'live',
    name: 'GENERAL.LIVE',
    link: '/sport/live',
  },
  {
    icon: 'bets',
    name: 'GENERAL.BETS',
    action: () => toggleCoupon(),
  },
  {
    icon: 'games',
    name: 'GENERAL.GAMES',
    link: '/games',
  },
  {
    icon: 'deposit',
    name: 'GENERAL.DEPOSIT',
    link: '/profile/deposits',
  },
])

const scrollLockCustomPlugin = inject('scrollLockCustomPlugin')

const showCoupon = ref(false)

function toggleCoupon(type) {
  if (type === 'close') {
    showCoupon.value = false
  } else {
    showCoupon.value = !showCoupon.value
  }

  showCoupon.value
    ? scrollLockCustomPlugin.disableScroll()
    : scrollLockCustomPlugin.enableScroll()
}

function isActive(link) {
  return route.fullPath.includes(link)
}

function goTo(item) {
  if (item.action) {
    item.action()
  } else {
    router.push(item.link)
  }
}
</script>

<template>
  <footer class="footer">
    <div class="footer__container">
      <div
        v-for="item in footerItems"
        :key="item.name"
        :class="{ footer__item_active: isActive(item.link) }"
        class="footer__item"
        @click="goTo(item)"
      >
        <div class="footer__item-icon">
          <svg class="footer__item-icon-component">
            <use :href="`#icon-${item.icon}`" />
          </svg>
        </div>
        <div class="footer__item-text">{{ t(item.name) }}</div>
      </div>
    </div>
  </footer>

  <TheCoupon :show="showCoupon" @toggleCoupon="toggleCoupon" />

  <transition name="fade">
    <div
      v-if="showCoupon"
      class="footer__blur"
      @click="toggleCoupon('close')"
    ></div>
  </transition>
</template>

<style lang="scss" scoped>
@import 'the-footer';
</style>
