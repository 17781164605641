<script setup>
import { computed } from 'vue'

const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
})

const icon = computed(() => `#${props.icon}`)
</script>
<template>
  <svg v-bind="$attrs">
    <use :href="icon" />
  </svg>
</template>

<style lang="scss" scoped></style>
