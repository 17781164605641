<script setup>
import { computed } from '@vue/runtime-core'

const props = defineProps({
  value: {
    type: [String, Number, Object, null],
    default: null,
    required: true,
  },
  label: {
    type: [String, Number],
    default: '',
  },
  option: {
    type: [String, Number, Object],
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['update:value'])

const isChecked = computed(() => {
  return JSON.stringify(props.value) === JSON.stringify(props.option)
})
</script>

<template>
  <div
    :class="{ disabled: props.disabled, readonly: props.readonly }"
    class="radio"
    @click="emit('update:value', props.option)"
  >
    <span :class="{ checked: isChecked }" class="radio__checkmark"></span>

    <slot name="label">
      <span v-if="props.label" class="radio__label">
        {{ props.label }}
      </span>
    </slot>
  </div>
</template>

<style lang="scss" scoped>
@import './base-radio-button';
</style>
