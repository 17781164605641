<script setup>
import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
import AuthLayout from '@/components/layouts/AuthLayout.vue'

import { computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

const router = useRouter()
const route = useRoute()
const { t } = useI18n()

router.beforeEach((to) => {
  const title = to.meta.pageTitle
  document.title = title ? t(title) : process.env.SITE_NAME
  window.scrollTo(0, 0)
})

const layout = computed(() => {
  if (route.meta.layout === 'default') {
    return DefaultLayout
  } else if (route.meta.layout === 'auth') {
    return AuthLayout
  } else {
    return DefaultLayout
  }
})
</script>

<template>
  <component :is="layout">
    <Suspense>
      <RouterView />
    </Suspense>
  </component>
</template>
