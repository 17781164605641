<script setup>
import TheHeader from '@/components/general/header/TheHeader'
import TheFooter from '@/components/general/the-footer/TheFooter'
import IconsManager from '@/components/general/IconsManager'
import ModalsManager from '@/components/general/modals-manager/ModalsManager.vue'
import PullRefresh from 'pull-refresh-vue3'

import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

const route = useRoute()
const { t } = useI18n()

const isMain = computed(() => route.fullPath === '/')

const reloadPage = () => {
  if (window && window.location) {
    window.location.reload()
  }
}
</script>

<template>
  <PullRefresh
    pulling-text="pull to refresh"
    loosing-text="loose to refresh"
    loading-text="updating.."
    success-text=""
    head-height="68"
    @refresh="reloadPage"
  >
    <div :class="{ 'default-layout_main': isMain }" class="default-layout">
      <div class="default-layout__container">
        <div class="default-layout__header">
          <TheHeader />
        </div>

        <div class="default-layout__content">
          <slot></slot>
        </div>

        <h1 class="default-layout__landscape">
          {{ t('GENERAL.LANDSCAPE_CAUTION') }}
          <br />
          {{ t('GENERAL.LANDSCAPE_CAUTION_DOWNLOAD') }}
        </h1>

        <div class="default-layout__footer">
          <TheFooter />
        </div>
      </div>
    </div>
  </PullRefresh>

  <IconsManager class="default-layout__icons-manager" />

  <ModalsManager class="default-layout__modals" />
</template>

<style lang="scss" scoped>
@import '@/assets/scss/layouts/defaul-layout';
</style>
