<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  withShadow: {
    type: Boolean,
    default: false,
    required: false,
  },
})
</script>

<template>
  <div class="card-container">
    <div
      :class="{ 'card-container__border-shadow': props.withShadow }"
      class="card-container__border"
    />

    <div class="card-container__content">
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'simple-card-container';
</style>
