<script setup>
import BaseIcon from '@/components/base/base-icon/BaseIcon.vue'

import { defineProps } from 'vue'

/* Sizes
  @ width: String
  @ height: String
*/

const props = defineProps({
  title: {
    type: String,
    default: '',
    required: false,
  },
  description: {
    type: String,
    default: '',
    required: false,
  },
  icon: {
    type: String,
    default: '',
    required: false,
  },
  iconSize: {
    type: Object,
    default: null,
    required: false,
  },
})
</script>

<template>
  <div v-if="props.title" class="modal-header">
    <span v-if="props.icon" class="modal-header__icon">
      <BaseIcon
        :icon="props.icon"
        :style="{
          width: `${props.iconSize?.width}px`,
          height: `${props.iconSize?.height}px`,
        }"
        class="modal-header__icon-component"
      />
    </span>

    <span class="modal-header__title">
      {{ props.title }}
      <span v-if="props.description" class="modal-header__description">
        {{ props.description }}
      </span>
    </span>
  </div>
</template>

<style lang="scss" scoped>
@import './modal-header';
</style>
